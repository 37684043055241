import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

import { GRAY_COLORS } from '../../../constants/colors';
import { fileStatus } from '../../../constants/fileStatus';

import { useStore } from '../../../hooks/useStore';
import Flex from '../../utils/flex/Flex';
import { Headline2 } from '../../typography/Headlines';
import { EMPTY_KNOWLEDGE_MODEL } from '../../../models/KnowledgeBaseModel';
import Status from '../../status/StatusLabel';
import { getStatusType } from './FileListViewRow';

const KnowledgeBaseHeader = () => {
  const {
    knowledgeBaseStore: { currentKnowledgeBase, setCurrentKnowledgeBase },
    localizationStore: { i18next: i18n },
    appState: { fileApi },
  } = useStore();

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Flex sx={{ alignItems: 'center' }}>
      <Headline2
        onClick={() => {
          setCurrentKnowledgeBase(EMPTY_KNOWLEDGE_MODEL);
          setSearchParams({});
        }}
        style={currentKnowledgeBase.id ? { fontWeight: '400', cursor: 'pointer' } : { cursor: 'pointer' }}
      >
        {`${i18n.t('sidebarMenu.knowledgeBase.action')}`}
      </Headline2>
      {currentKnowledgeBase.id && (
        <>
          <Typography sx={{ color: GRAY_COLORS.GRAY_5, fontSize: '18px', padding: '0 8px' }}>/</Typography>
          <Headline2 style={{ cursor: 'pointer' }}>{currentKnowledgeBase.name}</Headline2>
          <Status
            sx={{ marginLeft: '8px' }}
            type={getStatusType(currentKnowledgeBase.status)}
            label={currentKnowledgeBase.status}
          />
          {(currentKnowledgeBase.status === fileStatus.FAILED_PROCESSING ||
            currentKnowledgeBase.status === fileStatus.FAILED_REDACTION) && (
            <IconButton
              onClick={() => {
                fileApi.startProcessingKnowledgeBase(currentKnowledgeBase.id);
              }}
            >
              <ReplayOutlinedIcon />
            </IconButton>
          )}
        </>
      )}
    </Flex>
  );
};

export default observer(KnowledgeBaseHeader);
