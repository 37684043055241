import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { useStore } from '../../hooks/useStore';
import FortressResultsPage from './ResultsPages/FortressResultsPage';
import CallTranscriptResultsPage from './ResultsPages/CallTranscriptResultsPage';
import { WorkflowRun } from '../../models/Workflows';
import {
  isCallTranscriptResult,
  isDynamicJsonObject,
  isFortressResultInterface,
  isHtmlString,
  isResumeParserResponseArray,
} from './ResultsPages/utils/typeGuards';
import WorkflowResultPageBanner from './ResultsPages/components/WorkflowResultPageBanner';
import HtmlStringResultsPage from './ResultsPages/HtmlStringResultsPage';
import FieldsExtractionResultPage from './ResultsPages/FieldsExtractionResultPage';
import ResumeParserResultsPage from './ResultsPages/ResumeParserResultsPage';

const resultPages: Record<string, any> = {
  questionsAndAnswers: <FortressResultsPage />,
  callTranscript: <CallTranscriptResultsPage />,
  htmlString: <HtmlStringResultsPage />,
  dynamicObject: <FieldsExtractionResultPage />,
  resumeParser: <ResumeParserResultsPage />,
  empty: <div />,
};

const getComponentKeyBasedOnResultType = (workflowRun: WorkflowRun) => {
  const { result } = workflowRun;

  if (isFortressResultInterface(result)) {
    return 'questionsAndAnswers';
  }

  if (isResumeParserResponseArray(result)) {
    return 'resumeParser';
  }

  if (isCallTranscriptResult(result)) {
    return 'callTranscript';
  }

  if (isDynamicJsonObject(result)) {
    return 'dynamicObject';
  }

  if (isHtmlString(result)) return 'htmlString';

  return 'empty';
};

const WorkflowResultsComponent = () => {
  const {
    workFlowStore: { selectedWorkflowRun },
  } = useStore();

  return (
    <>
      <WorkflowResultPageBanner />
      {resultPages[getComponentKeyBasedOnResultType(selectedWorkflowRun)]}{' '}
    </>
  );
};

export default observer(WorkflowResultsComponent);
