const fetchBlob = async (url: string) => {
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error('Error fetching Blob');
  }

  return response.blob();
};

const createFileFromBlob = (blob: Blob, fileName: string) => {
  const fileType = blob.type;

  return new File([blob], fileName, {
    type: fileType,
    lastModified: new Date().getTime(),
  });
};

export const fetchAndCreateFile = async (url: string, fileName: string) => {
  try {
    const blob = await fetchBlob(url);
    const file = createFileFromBlob(blob, fileName);
    console.log('File created:', file);
    return file;
  } catch (error) {
    console.error('Error when fetching/creating S3 file:', error);
  }
};
