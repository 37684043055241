import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react';

import { COLOR_PRIMARY, GRAY_COLORS } from '../../../constants/colors';

import Flex from '../../../components/utils/flex/Flex';
import { Headline3 } from '../../../components/typography/Headlines';
import { CustomSearchBar } from '../../../components/CustomSearchBar/CustomSearchBar';
import AppTable from '../../../components/table/AppTable';
import { LoadingSpinner } from '../../../components/spinner/LoadingSpinner';
import { useStore } from '../../../hooks/useStore';
import { OrganizationModel } from '../../../models/OrganizationModel';
import { filterObjectsByKeys } from '../../../utils/filterObjectsByKeys';
import Button from '../../../components/buttons/Button';
import ModelTableRow from '../../../components/knowledgeBase/ModelTableRow';
import { Paths } from '../../../constants/routes';
import { colors } from '@material-ui/core';

type AgentTableProps = {
  openEditScreen: () => void;
  setIsDirtyState: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddDefaultModelModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderText: React.Dispatch<React.SetStateAction<string>>;
};

const AgentTable = ({
  openEditScreen,
  setIsAddDefaultModelModalOpen,
  setHeaderText,
  setIsDirtyState,
}: AgentTableProps) => {
  const {
    modelStore: { findAllOrganizationModels, organizationModels },
    localizationStore: { i18next: i18n },
  } = useStore();

  const [pageLoading, setPageLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const [filteredModels, setFilteredModels] = useState(organizationModels);
  const navigate = useNavigate();


  useEffect(() => {
    (async () => {
      setPageLoading(true);
      await findAllOrganizationModels();
      setPageLoading(false);
    })();
  }, []);

  useEffect(() => { }, [searchValue]);

  useEffect(() => {
    if (!searchValue) {
      setFilteredModels(organizationModels);
    }

    const newFilteredModels = filterObjectsByKeys(
      organizationModels,
      ['displayName', 'modelVersion'],
      searchValue
    ).filter((item: OrganizationModel) => {
      return !(selectedFilter !== 'all' && item.providerName !== selectedFilter);
    });

    setFilteredModels(newFilteredModels);
  }, [searchValue, selectedFilter, organizationModels]);

  const tableHeaderColumnNames = [
    {
      name: i18n.t('agentList.modal.column.chosenModel'),
      width: 15,
    },
    {
      name: i18n.t('agentList.modal.column.agent'),
      width: 15,
    },
    {
      name: i18n.t('agentList.modal.column.integrations'),
      width: 30,
    },
    {
      name: i18n.t('agentList.modal.column.personaDescription'),
      width: 30,
    },
    {
      name: i18n.t('agentList.modal.column.Action'),
      width: 6,
    },
  ];

  const handleOnClick = () => {
    navigate(`${Paths.ADD_AGENT}`);
  };

  if (pageLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Box>
          <Headline3>{i18n.t('agentList.header')}</Headline3>
          <Typography variant={'body2'} sx={{ marginTop: '8px', marginBottom: '24px', color: GRAY_COLORS.GRAY_7 }}>
            {i18n.t('agentList.button.subtitle')}
          </Typography>
        </Box>
        <Box>
          <Button
            // onClick={handleOnClick}
            sx={{
              color: 'gray',
              padding: '9px 16px !important',
              height: '40px !important',
              width: 'fit-content',
              fontWeight: 400,
            }}
          >
            {i18n.t('agentList.button.addAgent')}
          </Button>
        </Box>
      </Flex>

      <Flex>
        <CustomSearchBar
          onCancelSearch={() => setSearchValue('')}
          onChange={setSearchValue}
          sx={{ flex: 6, marginRight: '20px', marginBottom: '16px' }}
          placeholder={'Search Agent'}
        />
      </Flex>

      <AppTable style={{ flex: 1, overflow: 'scroll' }} noBorder tableHeaderColumnNames={tableHeaderColumnNames}>
        {filteredModels.map((model, index) => {
          return (
            <ModelTableRow
              model={model}
              index={index}
              // setIsDirtyState={setIsDirtyState}
              // openAddDefaultModelModal={(headerTextValue: React.SetStateAction<string>) => {
              //   setHeaderText(headerTextValue);
              //   setIsAddDefaultModelModalOpen(true);
              // }}
              openEditScreen={openEditScreen}
            />
          );
        })}
      </AppTable>
    </>
  );
};

export default observer(AgentTable);
