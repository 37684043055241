import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Autocomplete, TextField, Checkbox } from '@mui/material';
import { observer } from 'mobx-react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router';
import { Paths } from '../../constants/routes';
import CustomModal from './CustomModal';
import AskQuestions from '../Fortress/AskQuestions';
import { useStore } from '../../hooks/useStore';
import Button from '../buttons/Button';
import styled from 'styled-components';
import { CreateAdcbSolutionDto } from '../../models/CreateFortressSolutionDto';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SelectADCBModal = ({ isOpen, onClose }: ModalProps) => {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  const handleAutocompleteChange = (event: any, newValues: any) => {
    setSelectedValues(newValues);
  };

  const {
    conversationStore: {
      createAdcbSolution,
      adcbSolution,
      fortressFiles,
      setIsBackBtnUsed,
      setAgentFiles,
      setFortressQuestions,
      isBackBtnUsed,
    },
  } = useStore();

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSend = async () => {
    const fileNamesList = fortressFiles.map(file => file.key);

    const createAdcbSolutionDto: CreateAdcbSolutionDto = {
      client_id: 'Just_A_Test_INC',
      question_id: 'None',
      solution: [
        {
          name: 'simple_list_query',
          class: 'SimpleListQuery',
          kwargs: {
            bucket_name: `dev-avm-documents-addvaluemachine`,
            question: selectedValues,
            file_list: fileNamesList,
            textract_min_confidence: 60,
            remove_duplicates: false,
            llm_kwargs: {
              model_id: 'mistral.mixtral-8x7b-instruct-v0:1',
              model_kwargs: {
                max_tokens: 1024,
                temperature: 0,
                top_p: 0.9,
                top_k: 200,
              },
            },
            embedding_info: {
              text_concatenation_level: 'file_name',
              embed_llm_kwargs: { model_id: 'amazon.titan-embed-text-v1' },
              tok_llm_kwargs: {
                model_id: 'mistral.mixtral-8x7b-instruct-v0:1',
              },
              text_concatenation_hierarchy: ['file_name', 'page', 'block', 'chunk', 'line', 'span'],
            },
            reference_prompt:
              'This is a conversation between a human and a helpful assistant who never makes up answers. Human: Please extract information about {query} from the following document in triple quotes: """{context}""". If the information cannot be found, say "no" and do not make up an answer. Reply only with the answer in unformatted text. Attempt to translate any Arabic text into English. If the Arabic text cannot be translated, keep it.',
          },
        },
      ],
    };

    setLoading(true);
    await createAdcbSolution(createAdcbSolutionDto);
    setLoading(false);
    onClose();
    setIsBackBtnUsed(false);
    navigate(`${Paths.ADCB_RESULT}`);
  };

  const queriesForAdcb = [
    'License number',
    'Legal form or type',
    'Company trade name',
    'Incorporation or establishment date',
    'Issue date',
    'Expiry date',
    'Commercial activities',
    'Address',
    'Investor Name',
    'Investor Role',
    'Investor Nationality',
    'Investor Passport No.',
    'Investor number and percent of shares',
  ];

  useEffect(() => {
    if (!isBackBtnUsed) {
      setFortressQuestions([{ id: '1', value: '' }]);
      setAgentFiles([]);
      setIsBackBtnUsed(false);
    }
  }, []);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      sx={{ flexDirection: 'column', alignItems: 'end', width: '600px', padding: '24px', gap: '12px' }}
      key={'Select-fortress'}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Typography sx={{ mt: 2, mb: 1 }}>File Upload</Typography>

        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ width: '100%' }}>
        <>
          <Autocomplete
            multiple
            disablePortal
            id="combo-box-demo"
            value={selectedValues}
            options={queriesForAdcb}
            onChange={handleAutocompleteChange}
            disableCloseOnSelect
            renderInput={({ inputProps, ...rest }) => {
              return <AutoCompleteTextField {...rest} inputProps={{ ...inputProps }} placeholder={'select query'} />;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option}
              </li>
            )}
          />
          <AskQuestions activeStep={activeStep} setLoading={setLoading} />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} loading={loading}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleSend} loading={loading}>
              {'Submit'}
            </Button>
          </Box>
        </>
      </Box>
    </CustomModal>
  );
};

export default observer(SelectADCBModal);

const AutoCompleteTextField = styled(TextField)`
  & .MuiInputBase-root {
    height: unset;
  }
  & .MuiInputLabel-root {
    top: 0
  },
  & .MuiInputLabel-shrink {
    top: 0
  },
`;
