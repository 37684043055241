import { CancelTokenSource } from 'axios';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import autoBind from 'auto-bind';

import { fileStatus } from '../constants/fileStatus';

import { ConversationApi } from '../api/ConversationApi';
import {
  Conversation,
  ConversationSettings,
  CreateConversationDto,
  DEFAULT_SETTINGS,
  INITIAL_CONVERSATION,
  ShareConversationModel,
} from '../models/Conversation';
import { Chat, EMPTY_CHAT } from '../models/Chat';
import { CreateCompletionRequestDto } from '../models/CreateCompletionDto';
import { EMPTY_FILE, File } from '../models/File';
import { ChatApi } from '../api/ChatApi';
import { UserApi } from '../api/UserApi';
import { FileApi } from '../api/FileApi';
import RootStore from './RootStore';
import { OrganizationModel } from '../models/OrganizationModel';
import { CreateFortressSolutionDto, CreateAdcbSolutionDto } from '../models/CreateFortressSolutionDto';
import { FileAndDocumentKey } from '../components/DragAndDropFileInput/DragAndDropFileInput';
import { RespondToObjectionDto } from '../models/RespondToObjectionDto';

export class ConversationStore {
  rootStore: RootStore;
  conversationApi: ConversationApi;
  chatApi: ChatApi;
  userApi: UserApi;
  fileApi: FileApi;

  conversations: Conversation[] = [];
  conversationsByFile: Conversation[] = [];
  currentConversation: Conversation = INITIAL_CONVERSATION;
  auditLogs: Array<Conversation> = [INITIAL_CONVERSATION];

  conversationSettingsState: ConversationSettings = DEFAULT_SETTINGS;
  secondaryConversationSettingsState: ConversationSettings = DEFAULT_SETTINGS;
  multipleModelsRedactedChatMessage: Chat = EMPTY_CHAT;
  completionMessage: string | undefined;

  messageInput: string = '';
  selectedPromptId: string = '';
  uploadProgress: number = 0;
  uploadInProgress: boolean = false;
  uploadingConversationId: string = '';
  uploadingFileId: string = '';
  showFilePreview: boolean = false;
  previewFile: File = EMPTY_FILE;
  previewFileInitialPage: number = 1;
  shouldInputBeFocused: boolean = false;
  isSecondaryModelSelected: boolean = false;
  sharedWithMe: Conversation[] = [];
  newConversations: number = 0;
  cancelUploadToken: CancelTokenSource | undefined;
  isLoadingConversation: boolean = false;
  dataAnalysisEnablingPending: boolean = false;
  showEditConversationTitleModal: boolean = false;

  // Fortress - Solution
  fortressSolutionId: string = '';
  fortressSolution: any = {};
  adcbSolution: any = {};
  responseToObjectionSolution: any = {};
  fortressFiles: any[] = [];
  fortressQuestions: { id: string; value: string }[] = [{ id: '1', value: '' }];
  filesForAgent: FileAndDocumentKey[] = [];
  questionsForAgent: any[] = [];
  isAgentModalOpen: boolean = false;
  isBackBtnUsed: boolean = false;
  promptModel: boolean = false;

  constructor(
    rootStore: RootStore,
    conversationApi: ConversationApi,
    chatApi: ChatApi,
    userApi: UserApi,
    fileApi: FileApi
  ) {
    this.rootStore = rootStore;
    this.conversationApi = conversationApi;
    this.chatApi = chatApi;
    this.userApi = userApi;
    this.fileApi = fileApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setSelectedPromptModel(value: boolean) {
    runInAction(() => {
      this.promptModel = value;
    });
  }
  setAgentFiles(files: any[]) {
    runInAction(() => {
      this.filesForAgent = files;
    });
  }
  // Fortress solution - code
  setFortressQuestions(newArray: { id: string; value: string }[]) {
    runInAction(() => {
      this.fortressQuestions = newArray;
    });
  }

  setFortressSolutionFiles(files: any[]) {
    runInAction(() => {
      this.fortressFiles = files;
    });
  }

  setFortressSolutionValue(value: any) {
    runInAction(() => {
      this.fortressSolution = value;
    });
  }

  setFortressSolutionId(id: string) {
    runInAction(() => {
      this.fortressSolutionId = id;
    });
  }

  setIsBackBtnUsed(value: boolean) {
    runInAction(() => {
      this.isBackBtnUsed = value;
    });
  }

  setIsAgentModal(value: boolean) {
    runInAction(() => {
      this.isAgentModalOpen = value;
    });
  }

  async createFortressSolution(createFortressSolutionDto: CreateFortressSolutionDto) {
    const res = await this.conversationApi.createFortressSolution(createFortressSolutionDto);

    runInAction(() => {
      this.fortressSolution = res;
    });
  }

  async createAdcbSolution(createAdcbSolutionDto: CreateAdcbSolutionDto) {
    const res = await this.conversationApi.createAdcbSolution(createAdcbSolutionDto);

    runInAction(() => {
      this.adcbSolution = res;
    });
  }

  async respondToObjection(respondToObjectionDto: RespondToObjectionDto) {
    const res = await this.conversationApi.respondToObjection(respondToObjectionDto);

    runInAction(() => {
      this.responseToObjectionSolution = res;
    });

    return res;
  }

  setShowEditConversationTitleModal(value: boolean) {
    runInAction(() => {
      this.showEditConversationTitleModal = value;
    });
  }

  setCurrentConversationAndConversationsArray(conversation: Conversation) {
    runInAction(() => {
      this.currentConversation = conversation;
    });
  }

  setIsLoading(loading: boolean) {
    runInAction(() => {
      this.isLoadingConversation = loading;
    });
  }

  setCompletionMessage(message: string | undefined) {
    runInAction(() => {
      this.completionMessage = message;
    });
  }

  setMultipleModelsRedactedChatMessage(value: Chat) {
    runInAction(() => {
      this.multipleModelsRedactedChatMessage = value;
    });
  }

  setIsSecondaryModelSelected(isSecondaryModelSelected: boolean) {
    runInAction(() => {
      this.isSecondaryModelSelected = isSecondaryModelSelected;
    });
  }

  setPrimaryConversationModelSettings(conversationSettings: ConversationSettings) {
    runInAction(() => {
      this.conversationSettingsState = conversationSettings;
    });
  }

  setSecondaryConversationModelSettings(conversationSettings: ConversationSettings) {
    runInAction(() => {
      this.secondaryConversationSettingsState = conversationSettings;
    });
  }

  redactPiiData(text: string) {
    return this.conversationApi.redactPiiData(text);
  }

  setUploadProgress(value: number) {
    runInAction(() => {
      this.uploadInProgress = true;
      this.uploadProgress = value;
    });
  }

  setIsUploadInProgress(value: boolean) {
    runInAction(() => {
      this.uploadInProgress = value;
    });
  }

  setUploadingConversationId(id: string) {
    runInAction(() => {
      this.uploadingConversationId = id;
    });
  }

  setUploadingFileId(id: string) {
    runInAction(() => {
      this.uploadingFileId = id;
    });
  }

  setPreviewFile(file: File, skipFileIdCheck = false) {
    console.log('file. ------------------->> ', file.name);
    runInAction(() => {
      if (this.previewFile.id === file.id && !skipFileIdCheck) {
        this.previewFile = EMPTY_FILE;
        this.showFilePreview = false;

        return;
      }

      console.log('setting ------------------->> ', toJS(file));

      this.previewFile = file;
      this.showFilePreview = true;
    });
  }

  setPreviewFileInitialPage(page: number) {
    runInAction(() => {
      this.previewFileInitialPage = page;
    });
  }

  clearPreviewFile() {
    runInAction(() => {
      this.previewFile = EMPTY_FILE;
    });
  }

  setShouldInputBeFocused(value: boolean) {
    runInAction(() => {
      this.shouldInputBeFocused = value;
    });
  }

  toggleShowFilePreview() {
    runInAction(() => {
      if (this.showFilePreview) {
        this.clearPreviewFile();
      }

      this.showFilePreview = !this.showFilePreview;
    });
  }

  setCancelUploadToken(token: CancelTokenSource) {
    runInAction(() => {
      this.cancelUploadToken = token;
    });
  }

  setDataAnalysisEnablingPending(value: boolean) {
    runInAction(() => {
      this.dataAnalysisEnablingPending = value;
    });
  }

  setEmptyConversation(useGivenSettinges?: boolean) {
    runInAction(() => {
      this.currentConversation = INITIAL_CONVERSATION;

      if (!this.selectedPromptId && !useGivenSettinges) {
        this.conversationSettingsState = {
          ...DEFAULT_SETTINGS,
          model: this.rootStore.modelStore.defaultOrganizationModel.modelVersion || DEFAULT_SETTINGS.model,
          providerName:
            this.rootStore.modelStore.defaultOrganizationModel.providerName || DEFAULT_SETTINGS.providerName,
          modelDisplayName:
            this.rootStore.modelStore.defaultOrganizationModel.displayName || DEFAULT_SETTINGS.modelDisplayName,
        };
      }

      this.secondaryConversationSettingsState = {
        ...DEFAULT_SETTINGS,
        model: this.rootStore.modelStore.defaultOrganizationModel.modelVersion || DEFAULT_SETTINGS.model,
        modelDisplayName:
          this.rootStore.modelStore.defaultOrganizationModel.displayName || DEFAULT_SETTINGS.modelDisplayName,
      };
      this.isSecondaryModelSelected = false;
      this.showFilePreview = false;
    });
  }

  setPartialConversation(partialConversation: Conversation, message: string) {
    const newConversation = {
      ...partialConversation,
      chats: [
        { ...EMPTY_CHAT, message, isUserChat: true },
        { ...EMPTY_CHAT, loading: true },
      ],
    };

    const indexInsideConversationsArray = this.conversations.findIndex(
      conversation => conversation.id === newConversation.id
    );

    runInAction(() => {
      this.currentConversation = newConversation;

      if (indexInsideConversationsArray > -1) {
        const newConversationsArray = [...this.conversations];
        newConversationsArray[indexInsideConversationsArray] = newConversation;
        this.conversations = newConversationsArray;

        return;
      }

      this.conversations = [newConversation, ...this.conversations];
    });
  }

  setInitialConversation(
    text: string,
    model: string,
    providerName: string,
    temperature: number,
    maxTokens: number,
    user: any
  ) {
    const initialConversation = {
      ...INITIAL_CONVERSATION,
      model,
      providerName,
      temperature,
      maxTokens,
      user,
      chats: [
        { ...EMPTY_CHAT, message: text, isUserChat: true },
        { ...EMPTY_CHAT, loading: true },
      ],
    };

    runInAction(() => {
      this.currentConversation = initialConversation;
    });

    return initialConversation;
  }

  updateConversationSettings(conversation: Conversation, model?: OrganizationModel) {
    runInAction(() => {
      this.conversationSettingsState = {
        model: model ? model.modelVersion : conversation.model,
        modelDisplayName: model?.displayName || 'Missing Model',
        accuracy: Number(conversation.temperature),
        length: conversation.maxTokens,
        providerName: conversation.providerName,
      };
      this.secondaryConversationSettingsState = DEFAULT_SETTINGS;
      this.isSecondaryModelSelected = false;
    });
  }

  updateConversationAccuracy(accuracy: number) {
    runInAction(() => {
      this.conversationSettingsState = { ...this.conversationSettingsState, accuracy };
    });
  }

  updateConversationLength(length: number) {
    runInAction(() => {
      this.conversationSettingsState = { ...this.conversationSettingsState, length };
    });
  }

  updateConversationModelAndProvider(model: string, providerName: string, displayName: string) {
    runInAction(() => {
      this.conversationSettingsState = {
        ...this.conversationSettingsState,
        model: model,
        providerName,
        modelDisplayName: displayName,
      };
    });
  }

  setMessageInput(text: string, focusInput: boolean = false) {
    runInAction(() => {
      this.messageInput = text;

      if (!text.length) {
        this.selectedPromptId = '';
      }

      if (focusInput) {
        this.shouldInputBeFocused = true;
      }
    });
  }

  setSelectedPromptId(id: string) {
    runInAction(() => {
      this.selectedPromptId = `${id}`;
    });
  }

  getChatsForErrorHandling(errorMessage: string) {
    return this.currentConversation.chats.slice(0, -1).concat({
      ...EMPTY_CHAT,
      message: '',
      error: errorMessage,
    });
  }

  createCompletion(createCompletionRequestDto: CreateCompletionRequestDto) {
    return this.conversationApi.createCompletion(createCompletionRequestDto);
  }

  async createConversation(
    createConversationDto: CreateConversationDto,
    createWithoutChatResponse: boolean = false,
    forcefullyOverrideConversation: boolean = false
  ) {
    const promptId = this.selectedPromptId;
    let overrideConversation = true;

    if (!(createConversationDto.chat?.files?.length && createConversationDto.chat?.files[0].id)) {
      this.setMessageInput('');
    }

    let conversation = this.currentConversation;

    try {
      conversation = createWithoutChatResponse
        ? await this.conversationApi.createWithoutGettingChatResponse(createConversationDto)
        : await this.conversationApi.create({
            ...createConversationDto,
            chat: {
              ...createConversationDto.chat,
              ...(promptId && { promptId }),
            },
          });

      overrideConversation = conversation.id === this.currentConversation.id;
    } catch (error: any) {
      if (error.response && error.response.data) {
        conversation = {
          ...this.currentConversation,
          chats: this.getChatsForErrorHandling(error.response.data.error),
        };
      }
    }

    if (conversation.loadingResponse) {
      conversation.chats.push({ ...EMPTY_CHAT, loading: true, isUserChat: false });
    }

    const indexInsideConversationsArray = this.conversations.findIndex(
      conversationItem => conversationItem.id === conversation.id
    );

    const conversationModel = this.rootStore.modelStore.organizationModels.find(
      model => model.modelVersion === conversation.model
    );

    runInAction(() => {
      if (overrideConversation || createWithoutChatResponse) {
        this.updateConversationSettings(conversation, conversationModel);
        this.currentConversation = conversation;
      }

      if (indexInsideConversationsArray > -1) {
        const newConversationsArray = [...this.conversations];
        newConversationsArray[indexInsideConversationsArray] = { ...conversation, isStreaming: false };
        this.conversations = newConversationsArray;
        return;
      }

      this.conversations = [conversation, ...this.conversations];
    });

    if (!overrideConversation && !forcefullyOverrideConversation && !createWithoutChatResponse) {
      return false;
    }

    return conversation;
  }

  async addFileToConversation(
    conversationId: string,
    files: {
      id: string;
      name: string;
    }[]
  ) {
    await this.conversationApi.attachFile({ conversationId, files });

    return this.getConversationById(conversationId);
  }

  async getConversationById(id: string): Promise<Conversation> {
    const conversation = await this.conversationApi.getById(id);

    const conversationModel = this.rootStore.modelStore.organizationModels.find(
      model => model.modelVersion === conversation.model
    );

    const newChats = [...conversation.chats];

    if (conversation.loadingResponse) {
      newChats.push({ ...EMPTY_CHAT, loading: true });
    }

    runInAction(() => {
      this.currentConversation = { ...conversation, chats: newChats };

      this.updateConversationSettings(conversation, conversationModel);
    });

    return conversation;
  }

  async getAllUsersConversations() {
    const conversations = await this.conversationApi.getAllUsersConversations();

    runInAction(() => {
      this.conversations = conversations;
    });
  }

  async getUsersRecentConversations() {
    const conversations = await this.conversationApi.getUsersRecentConversations();

    runInAction(() => {
      this.conversations = conversations?.slice(0, 3) || [];
    });
  }

  async addChat(message: string) {
    let overrideConversation = true;

    runInAction(() => {
      this.currentConversation = {
        ...this.currentConversation,

        chats: [
          ...this.currentConversation.chats,
          { ...EMPTY_CHAT, message, isUserChat: true },
          { ...EMPTY_CHAT, loading: true, isUserChat: false },
        ],
      };
    });

    const promptId = this.selectedPromptId;
    this.setMessageInput('');
    let newConversation = this.currentConversation;
    try {
      newConversation = await this.conversationApi.addChat({
        conversationId: this.currentConversation.id,
        message,
        ...(promptId && { promptId }),
      });

      if (newConversation.loadingResponse) {
        newConversation.chats.push({ ...EMPTY_CHAT, loading: true, isUserChat: false });
      }

      overrideConversation = newConversation.id === this.currentConversation.id;
    } catch (error: any) {
      if (error.response && error.response.data) {
        newConversation = {
          ...this.currentConversation,
          chats: this.getChatsForErrorHandling(error.response.data.error),
        };
      }
    }

    const conversationModel = this.rootStore.modelStore.organizationModels.find(
      model => model.modelVersion === newConversation.model
    );

    const indexInsideConversationsArray = this.conversations.findIndex(
      conversationItem => conversationItem.id === newConversation.id
    );

    runInAction(() => {
      if (overrideConversation) {
        this.currentConversation = newConversation;
        this.updateConversationSettings(newConversation, conversationModel);
      }

      if (indexInsideConversationsArray > -1) {
        const newConversationsArray = [...this.conversations];
        newConversationsArray[indexInsideConversationsArray] = { ...newConversation, isStreaming: false };
        this.conversations = newConversationsArray;
      }
    });
  }

  updateStreamedMessageContent(message: string, loading: boolean) {
    const currentChatsArrayLength = this.currentConversation.chats.length;
    const indexOfCurrentConversation = this.conversations.findIndex(
      conversationItem => conversationItem.id === this.currentConversation.id
    );

    // Updating the chats array
    runInAction(() => {
      const newChats = [...this.currentConversation.chats];

      // This adds a new placeholder chat in case the user refreshes the page before the server is done creating it
      if (
        newChats[currentChatsArrayLength - 1]?.isUserChat ||
        newChats[currentChatsArrayLength - 1]?.wasWarned ||
        newChats[currentChatsArrayLength - 1]?.wasAnonymized
      ) {
        newChats.push({ ...EMPTY_CHAT });
      }

      newChats[newChats.length - 1] = {
        ...newChats[newChats.length - 1],
        loading,
        message,
      };

      this.currentConversation.chats = newChats;
    });
  }

  async getHistoryLogs() {
    const logs = await this.conversationApi.getHistoryLogs();

    runInAction(() => {
      this.auditLogs = logs;
    });
  }

  async updateConversation(conversationDto: Partial<Conversation>, propagateUpdateInConversationsArray = false) {
    const updatedConversation = await this.conversationApi.updateConversation(
      this.currentConversation.id,
      conversationDto
    );

    const conversationModel = this.rootStore.modelStore.organizationModels.find(
      model => model.modelVersion === updatedConversation.model
    );

    runInAction(() => {
      this.currentConversation = updatedConversation;
      this.updateConversationSettings(updatedConversation, conversationModel);

      if (propagateUpdateInConversationsArray) {
        const indexOfCurrentConversationInConversationsArray = this.conversations.findIndex(
          conversationInsideArray => conversationInsideArray.id === updatedConversation.id
        );

        if (indexOfCurrentConversationInConversationsArray > -1) {
          const newConversationsArray = [...this.conversations];
          newConversationsArray[indexOfCurrentConversationInConversationsArray] = updatedConversation;

          this.conversations = newConversationsArray;
        }
      }
    });
  }

  async removeConversation(id: string) {
    await this.conversationApi.remove(id);

    runInAction(() => {
      const indexOfDeletedItem = this.conversations.findIndex(conversation => conversation.id === id);

      if (id === this.currentConversation.id) {
        this.currentConversation = INITIAL_CONVERSATION;
      }

      if (indexOfDeletedItem > -1) {
        const newConversationsArray = [...this.conversations];
        newConversationsArray.splice(indexOfDeletedItem, 1);

        this.conversations = newConversationsArray;
      }
    });
  }

  async updateChatSentiment(chatId: string, sentiment: number) {
    const newChat = await this.chatApi.update(chatId, { sentiment });

    runInAction(() => {
      const chatIndex = this.currentConversation.chats.findIndex(chat => chat.id === newChat.id);

      if (chatIndex < 0) {
        return;
      }

      const updatedConversationChats = [...this.currentConversation.chats];
      updatedConversationChats[chatIndex] = newChat;

      this.currentConversation = {
        ...this.currentConversation,
        chats: updatedConversationChats,
      };
    });
  }

  async shareConversation(shareObj: ShareConversationModel) {
    await this.conversationApi.shareConversation(shareObj);
  }

  async getSharedWithMe() {
    const conversations = await this.conversationApi.getSharedWithMe();
    const conversationsWithSharedBy = await Promise.all(
      conversations.map(async (item: Conversation) => {
        if (item.sharedBy) {
          const user = await this.userApi.getUserDataById(item.sharedBy);
          return { ...item, sharedByName: user?.fullName };
        }
        return item;
      })
    );

    runInAction(() => {
      this.sharedWithMe = conversationsWithSharedBy;
    });
  }

  async getNewConversations() {
    const conversations = await this.conversationApi.getNewConversations();

    runInAction(() => {
      this.newConversations = conversations?.length;
    });
  }

  async cancelFileUpload() {
    if (!this.uploadingFileId) {
      return;
    }

    await this.fileApi.updateFileStatus(this.uploadingFileId, fileStatus.CANCELLED);

    runInAction(() => {
      this.uploadInProgress = false;
      this.uploadingConversationId = '';
    });

    this.getConversationById(this.currentConversation.id);
  }

  async getConversationsByFileId(fileId: string) {
    const conversations = await this.conversationApi.getConversationsByFileId(fileId);

    runInAction(() => {
      this.conversationsByFile = conversations;
    });
  }

  async addDataAnalysisBotChat(dataAnalysisMessage: string) {
    runInAction(() => {
      this.currentConversation = {
        ...this.currentConversation,
        chats: [...this.currentConversation.chats, { ...EMPTY_CHAT, loading: true, isUserChat: false }],
      };
    });

    const conversation = await this.conversationApi.addDataAnalysisBotChat(
      this.currentConversation.id,
      dataAnalysisMessage
    );

    runInAction(() => {
      this.currentConversation = conversation;
    });
  }
}
