export enum fileStatus {
  PENDING = 'PENDING',
  UPLOADED = 'UPLOADED',
  REDACTING = 'REDACTING',
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  FAILED_REDACTION = 'FAILED_REDACTION',
  FAILED_PROCESSING = 'FAILED_PROCESSING',
  CANCELLED = 'CANCELLED',
}
