import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';

import CustomModal from './CustomModal';
import { useStore } from '../../hooks/useStore';
import Button from '../buttons/Button';
import { fetchAndCreateFile } from '../../utils/fetchAndCreateFile';
import { Conversation, DEFAULT_SETTINGS, INITIAL_CONVERSATION } from '../../models/Conversation';
import { getDocumentsKey, uploadDocumentSecurely } from '../../utils/documentsS3';
import { ConversationTypes } from '../../constants/conversationTypes';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SelectBrotherHoodModal = ({ isOpen, onClose }: ModalProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      prompt: '',
      topn: 1,
    },
    reValidateMode: 'onChange',
  });

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const {
    localizationStore: { i18next: i18n },
    conversationStore: {
      setIsBackBtnUsed,
      setAgentFiles,
      setFortressQuestions,
      isBackBtnUsed,
      respondToObjection,
      setUploadingConversationId,
      setIsUploadInProgress,
      setUploadProgress,
      currentConversation,
      createConversation,
      addFileToConversation,
      setCancelUploadToken,
      setUploadingFileId,
    },
    fileStore: { createFile },
    userStore: { userData },
    modelStore: { organizationModels },
    appState: { s3DocumentsApi, fileApi },
  } = useStore();

  type FormInputs = {
    prompt: string;
    topn: number;
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const embeddingModel = {
    ...(organizationModels.find(item => item.isDefaultFileModel === true) || {}),
    accuracy: DEFAULT_SETTINGS.accuracy,
    length: DEFAULT_SETTINGS.length,
  };

  const assignFilesToConversation = async (
    files: {
      id: string;
      name: string;
    }[],
    conversationToBeAssignedFiles?: Conversation
  ) => {
    let conversation: Conversation | undefined = conversationToBeAssignedFiles;

    if (currentConversation && !currentConversation.id && !conversation?.id) {
      conversation =
        (await createConversation({
          type: ConversationTypes.Chat,
          model: embeddingModel.modelVersion || '',
          temperature: embeddingModel.accuracy,
          maxTokens: embeddingModel.maxTokens || 1024,
          providerName: embeddingModel.providerName,
          chat: {
            files: files,
          },
        })) || INITIAL_CONVERSATION;
    } else {
      conversation = await addFileToConversation(currentConversation.id || conversation?.id || '', files);
    }

    return conversation;
  };

  const onSubmit: any = async (data: FormInputs) => {
    try {
      setLoading(true);
      const res = await respondToObjection({ prompt: data.prompt, topn: Number(data.topn) });

      const filesToAddToConversation = [];
      let conversationToAssignFilesTo: Conversation | undefined;

      for (const file of res?.files || []) {
        const fileToUpload = await fetchAndCreateFile(file.url, file.name);

        if (fileToUpload) {
          const fileKey = getDocumentsKey(userData.id, fileToUpload.name);
          const createdFileEntry = await createFile({ key: fileKey, name: fileToUpload.name, skipRedaction: true });

          const conversation = await assignFilesToConversation(
            [{ id: createdFileEntry.id, name: createdFileEntry.name }],
            conversationToAssignFilesTo && conversationToAssignFilesTo
          );

          conversationToAssignFilesTo = conversation;

          setIsUploadInProgress(true);
          setUploadingConversationId(conversation.id);
          setUploadingFileId(createdFileEntry.id);
          setUploadProgress(0);

          if (process.env.REACT_APP_USE_DOCUMENT_ENCRYPTION === 'true') {
            const status = await s3DocumentsApi.uploadDocuments(fileToUpload, createdFileEntry.id, {
              setProgress: setUploadProgress,
              setCancelToken: setCancelUploadToken,
            });

            if (status === 201) {
              setIsUploadInProgress(false);
              fileApi.startProcessing(conversation.id, createdFileEntry.id);
            }
          }

          const preSignedPutUrl = await s3DocumentsApi.generateDocumentsWriteUrl(fileKey, fileToUpload.type);

          const status = await uploadDocumentSecurely(preSignedPutUrl, fileToUpload, {
            setProgress: setUploadProgress,
            setCancelToken: setCancelUploadToken,
          });

          if (status === 200) {
            setIsUploadInProgress(false);
            if (conversation.id) {
              fileApi.startProcessing(conversation.id, createdFileEntry.id);
            }
          }
          filesToAddToConversation.push(fileToUpload);
        }
      }

      setLoading(false);
      reset();
    } catch (error) {
      reset();
      console.log('error---->', error);
    }
  };

  useEffect(() => {
    if (!isBackBtnUsed) {
      setFortressQuestions([{ id: '1', value: '' }]);
      setAgentFiles([]);
      setIsBackBtnUsed(false);
    }
  }, []);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      sx={{ flexDirection: 'column', alignItems: 'end', width: '600px', padding: '24px', gap: '12px' }}
      key={'Select-fortress'}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Typography sx={{ mt: 2, mb: 1 }}>{i18n.t('objection.heading')}</Typography>

        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="row" gap={'1rem'}>
            <Box sx={{ flex: '3' }}>
              <Controller
                control={control}
                name="prompt"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <TextField
                    label="Input Prompt"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    value={value}
                    ref={ref}
                  />
                )}
              />
              {errors.prompt?.type === 'required' && <p style={{ color: 'red' }}>Required!</p>}
            </Box>
            <Box sx={{ flex: '1' }}>
              <Controller
                control={control}
                name="topn"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <TextField
                    type="number"
                    label="Number Of Files"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    value={value}
                    ref={ref}
                  />
                )}
              />
              {errors.topn?.type === 'required' && <p style={{ color: 'red' }}>Required!</p>}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} loading={loading}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleSubmit(onSubmit)} loading={loading}>
              {'Submit'}
            </Button>
          </Box>
        </form>
      </Box>
    </CustomModal>
  );
};

export default observer(SelectBrotherHoodModal);
