import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';

import { COLOR_WHITE } from '../../../constants/colors';
import { Roles } from '../../../constants/userRoles';
import { SIZES_SMALL } from '../../../constants/sizes';
import { Paths } from '../../../constants/routes';

import FullHeightScreenWrapper from '../../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import { Headline1 } from '../../../components/typography/Headlines';
import { useStore } from '../../../hooks/useStore';
import FlexRowSpaceBetween from '../../../components/utils/flex/FlexRowSpaceBetween';
import KnowledgeBasesListView from '../../../components/knowledgeBase/KnowledgeBasesListView';
import UploadFile from '../../../components/knowledgeBase/UploadFile';
import { LoadingSpinner } from '../../../components/spinner/LoadingSpinner';
import { EMPTY_KNOWLEDGE_MODEL } from '../../../models/KnowledgeBaseModel';
import FilesListView from '../../../components/knowledgeBase/components/FilesListView';
import KnowledgeBaseHeader from '../../../components/knowledgeBase/components/KnowledgeBaseHeader';
import CreateKnowledgeBase from '../../../components/knowledgeBase/CreateKnowledgeBase';
import { Conversation } from '../../../models/Conversation';
import Button from '../../../components/buttons/Button';
import Flex from '../../../components/utils/flex/Flex';
import { SocketEvents } from '../../../constants/events/SocketEvents';
import { fileStatus } from '../../../constants/fileStatus';

const PageContainer = styled(Box)`
  align-items: center;
  height: 100%;
  margin: 0;
  gap: 16px;
  background: ${COLOR_WHITE};
  border-radius: 8px;
  overflow: hidden;
  padding: 24px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

const KnowledgeBasePage = () => {
  const {
    localizationStore: { i18next: i18n },
    userStore: { userData },
    knowledgeBaseStore: {
      setCurrentKnowledgeBase,
      showFilePreview,
      getAllKnowledgeBasesByOrganization,
      uploadInProgress,
      currentKnowledgeBase,
      setCurrentKnowledgeBaseStatus,
      knowledgeBaseFiles,
    },
    conversationStore: { createConversation },
    modelStore: { findDefaultFileModel },
    socketStore: { joinRoom, subscribe, unSubscribeAllListenersFromSingleEvent, leaveRoom },
  } = useStore();
  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const onStartNewChat = async () => {
    setLoading(true);

    const defaultFileModel = await findDefaultFileModel();

    const conversation = await createConversation(
      {
        title: currentKnowledgeBase.name,
        model: defaultFileModel.modelVersion,
        providerName: defaultFileModel.providerName,
        maxTokens: 1024,
        temperature: 0.7,
        isStartedFromKnowledgeBase: true,
        type: 'chat',
        knowledgeBaseId: currentKnowledgeBase.id,
      },
      false,
      true
    );

    setLoading(false);
    navigate(`${Paths.CHAT}/${(conversation as Conversation).id || ''}`);
    localStorage.setItem('selectedItem', `${i18n.t('home.newConversation.chat')}`);
  };

  useEffect(() => {
    const knowledgeBaseId = searchParams.get('knowledgeBase');
    const room = `knowledgeBase-${knowledgeBaseId}`;

    joinRoom(room);
    subscribe(SocketEvents.knowledgeBaseStatusChanged, ({ status }) => {
      setCurrentKnowledgeBaseStatus(status);
    });

    (async () => {
      const knowledgeBases = await getAllKnowledgeBasesByOrganization();

      if (knowledgeBaseId) {
        const knowledgeBaseFromSearchParams = knowledgeBases.find(
          knowledgeBase => knowledgeBase.id === knowledgeBaseId
        );

        if (knowledgeBaseFromSearchParams) {
          setCurrentKnowledgeBase(knowledgeBaseFromSearchParams);
        }
      } else {
        setCurrentKnowledgeBase(EMPTY_KNOWLEDGE_MODEL);
      }

      setLoading(false);
    })();

    return () => {
      leaveRoom(room);
      unSubscribeAllListenersFromSingleEvent(SocketEvents.knowledgeBaseStatusChanged);
    };
  }, [searchParams]);

  useEffect(() => {
    if (!uploadInProgress) {
      (async () => {
        await getAllKnowledgeBasesByOrganization();
        setLoading(false);
      })();
    }
  }, [id, uploadInProgress, loading]);

  return (
    <FullHeightScreenWrapper
      sx={{ backgroundColor: 'transparent', height: 'calc(100vh - 56px)', flexDirection: 'row' }}
    >
      <PageContainer sx={{ width: showFilePreview ? '60%' : '100%' }}>
        {/* {userData.role == Roles.organizationAdmin ? ( */}
        <FlexRowSpaceBetween>
          <KnowledgeBaseHeader />
          {currentKnowledgeBase.id && (
            <Flex>
              <Button
                startIcon={<AddIcon />}
                sx={{
                  padding: '9px 16px !important',
                  height: '40px !important',
                  width: 'fit-content',
                  fontWeight: 400,
                  marginRight: '8px',
                }}
                onClick={() => onStartNewChat()}
                disabled={knowledgeBaseFiles.length === 0 || currentKnowledgeBase.status !== fileStatus.DONE}
              >
                New Chat
              </Button>
              <UploadFile />
            </Flex>
          )}
          {!currentKnowledgeBase.id && <CreateKnowledgeBase />}
        </FlexRowSpaceBetween>
        {/* ) : (
          <Headline1>{`${i18n.t('sidebarMenu.knowledgeBase.action')}`}</Headline1>
        )} */}

        {loading ? (
          <Box>
            <LoadingSpinner />
          </Box>
        ) : currentKnowledgeBase.id ? (
          <FilesListView />
        ) : (
          <KnowledgeBasesListView />
        )}
      </PageContainer>
    </FullHeightScreenWrapper>
  );
};

export default observer(KnowledgeBasePage);
