import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Close from '@mui/icons-material/Close';
import { observer } from 'mobx-react';

import { COLOR_BORDER_PRIMARY, COLOR_TEXT_PRIMARY } from '../../constants/colors';

import FullHeightScreenWrapper from '../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import Flex from '../../components/utils/flex/Flex';
import { useStore } from '../../hooks/useStore';
import ConversationFileCard from './components/Files/ConversationFileCard';
import FilePreview from './components/Files/FilePreview';
import { File } from '../../models/File';

const PageWrapper = styled(FullHeightScreenWrapper)`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${COLOR_BORDER_PRIMARY};
  padding: 0;
  // TODO: this is a hotfix for fortress solution
  height: calc(100vh - 56px - 48px - 70px);
`;

interface ConversationFilesProps {
  docFiles?: File[];
}
const ConversationFiles = ({ docFiles }: ConversationFilesProps) => {
  const {
    conversationStore: {
      toggleShowFilePreview,
      currentConversation: { files },
      previewFile,
    },
    localizationStore: { i18next: i18n },
  } = useStore();

  return (
    <PageWrapper>
      {!previewFile.id ? (
        <>
          <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
            <Typography variant={'body1'} sx={{ fontWeight: 600, color: COLOR_TEXT_PRIMARY }}>
              {i18n.t('conversation.files.title')}
            </Typography>
            <IconButton size={'small'} onClick={() => toggleShowFilePreview()}>
              <Close fontSize={'small'} />
            </IconButton>
          </Flex>
          {Array.isArray(files) && files.length > 0 && (
            <Grid container spacing={'12px'} sx={{ padding: '16px' }}>
              {files?.map((file, index) => (
                <Grid item xs={6} key={`file-${index}`}>
                  <ConversationFileCard file={file} />
                </Grid>
              ))}
            </Grid>
          )}
          {!Array.isArray(files) && Array.isArray(docFiles) && docFiles.length > 0 && (
            <Grid container spacing={'12px'} sx={{ padding: '16px' }}>
              {docFiles?.map((file, index) => (
                <Grid item xs={6} key={`file-${index}`}>
                  <ConversationFileCard file={file} />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      ) : (
        <FilePreview file={previewFile} isGridViewIconDisabled={false} />
      )}
    </PageWrapper>
  );
};

export default observer(ConversationFiles);
