import { useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import { COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';

const KeyValue = ({ keyName, value, config }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  if (typeof value === 'object' && value !== null) {
    if (Array.isArray(value)) {
      return (
        <div style={{ marginBottom: '5px' }}>
          <Accordion
            sx={{
              '& .MuiCollapse-root': { border: 'none', marginLeft: '0px' },
            }}
          >
            <AccordionSummary onClick={toggleOpen} sx={{ cursor: 'pointer' }}>
              <Typography style={{ fontWeight: '500' }}>{keyName}:</Typography>
              <Typography style={{ marginLeft: '5px', color: GRAY_COLORS.GRAY_11 }}>{isOpen ? '▼' : '▶'}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ overflow: 'auto', maxHeight: '200px' }}>
              {isOpen &&
                value.map((item, index) =>
                  typeof item === 'object' ? (
                    <div key={index} style={{ marginBottom: '5px' }}>
                      <JsonRenderer json={item} config={config ? config[0] : null} inner={true} />
                    </div>
                  ) : (
                    <Typography style={{ fontWeight: '500', marginBottom: '5px' }}>
                      <ul>
                        <li>{String(item)}</li>
                      </ul>
                    </Typography>
                  )
                )}
            </AccordionDetails>
          </Accordion>
        </div>
      );
    } else {
      return (
        <div style={{ marginBottom: '0.5rem' }}>
          <Accordion
            sx={{
              '& .MuiCollapse-root': { border: 'none' },
            }}
          >
            <AccordionSummary onClick={toggleOpen} sx={{ cursor: 'pointer' }}>
              <Typography style={{ fontWeight: '500' }}>{keyName}:</Typography>
              <Typography style={{ marginLeft: '5px', color: GRAY_COLORS.GRAY_11 }}>{isOpen ? '▼' : '▶'}</Typography>
            </AccordionSummary>
            <AccordionDetails>{isOpen && <JsonRenderer json={value} config={config} inner={true} />}</AccordionDetails>
          </Accordion>
        </div>
      );
    }
  } else {
    return (
      <div style={{ marginBottom: '0.5rem' }}>
        <Typography style={{ fontWeight: '500' }}>
          {keyName === 'workflowRunName' ? (
            <Box>
              <span style={{ fontWeight: '500' }}>{keyName}:</span>
              <span style={{ color: GRAY_COLORS.GRAY_11, marginLeft: '4px' }}>{String(value)}</span>
            </Box>
          ) : (
            <ul>
              <li>{String(value)}</li>
            </ul>
          )}
        </Typography>
      </div>
    );
  }
};

export const JsonRenderer = ({ json, config, inner }: any) => {
  return (
    <Box sx={{ bgcolor: COLOR_WHITE }}>
      {!inner && (
        <Typography variant="h5" sx={{ fontWeight: 700, mb: '0.5rem' }}>
          Preview Workflow Run data:
        </Typography>
      )}
      {Object.keys(json)
        .filter(key => !config || config[key])
        .map(key => (
          <KeyValue
            key={key}
            keyName={key}
            value={json[key]}
            config={typeof config === 'object' && config[key] === true ? null : config[key]}
          />
        ))}
    </Box>
  );
};
