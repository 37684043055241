import Flex from '../../utils/flex/Flex';
import { getIconByName, MoreIcon, TypeIcon } from '../utils';
import { getTextOverflowEllipsisStyles } from '../../typography/utils';
import { SxProps, TableCell, Typography } from '@mui/material';
import UserAvatar from '../../UserAvatar/UserAvatar';
import Status from '../../status/StatusLabel';
import { Statuses } from '../../../constants/Statuses';
import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { File } from '../../../models/File';
import { fileStatus } from '../../../constants/fileStatus';
import FileSourceIcon from './FileSourceIcon';
import { useNavigate } from 'react-router';
import { useStore } from '../../../hooks/useStore';
import { Paths } from '../../../constants/routes';

type FileListViewRowProps = {
  handleClickMoreFiles: (e: React.MouseEvent<HTMLButtonElement>, file: File) => void;
  file: File;
};

export const getStatusType = (status: string) => {
  switch (status) {
    case fileStatus.DONE:
    case fileStatus.UPLOADED:
      return Statuses.allowed;
    case fileStatus.REDACTING:
    case fileStatus.PROCESSING:
    case fileStatus.PENDING:
      return Statuses.analyzing;
    case fileStatus.FAILED_PROCESSING:
    case fileStatus.FAILED_REDACTION:
    case fileStatus.CANCELLED:
      return Statuses.blocked;
    default:
      return Statuses.allowed;
  }
};

const FileListViewRow = ({ handleClickMoreFiles, file }: FileListViewRowProps) => {
  const {
    knowledgeBaseStore: { setSelectedFile },
  } = useStore();

  const navigate = useNavigate();

  const onPreviewFile = (file: File) => {
    setSelectedFile(file);
    navigate(`${Paths.KNOWLEDGE_BASE}/${file.id}`);
  };

  return (
    <TableRow
      hover={true}
      key={`folder-${file.id}`}
      sx={{ height: '48px', verticalAlign: 'center', cursor: 'pointer' }}
      onClick={() => {
        onPreviewFile(file);
      }}
    >
      <StyledTableCell align={'left'}>
        <Flex sx={{ alignItems: 'center' }}>
          <TypeIcon src={getIconByName(file.name)} alt={file.name} />
          <FileRowTypography sx={{ ...getTextOverflowEllipsisStyles(1) }}>{file.name}</FileRowTypography>
        </Flex>
      </StyledTableCell>

      <StyledTableCell align={'center'}>
        <FileSourceIcon source={file.source || ''} size={20} />
      </StyledTableCell>

      <StyledTableCell align={'left'}>
        <Flex>
          <UserAvatar user={file.user} />
          <FileRowTypography sx={{ marginLeft: '8px' }}>{file?.user?.fullName}</FileRowTypography>
        </Flex>
      </StyledTableCell>

      <StyledTableCell>{new Date(file.createdAt).toLocaleDateString()}</StyledTableCell>

      <StyledTableCell>{file.skipRedaction ? 'No' : 'Yes'}</StyledTableCell>

      <StyledTableCell>
        <Status type={getStatusType(file.status)} label={file.status} />
      </StyledTableCell>

      <StyledTableCell>
        <IconButton
          onClick={e => handleClickMoreFiles(e, file)}
          sx={{
            width: '20px',
            height: '20px',
            '&:hover': {
              backgroundColor: 'inherit',
            },
          }}
        >
          <MoreIcon sx={{ width: '20px', height: '20px' }} />
        </IconButton>
      </StyledTableCell>
    </TableRow>
  );
};

const StyledTableCell = styled(TableCell)`
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  background: inherit !important;
  padding-top: 14px;
  padding-bottom: 12px;
`;

const FileRowTypography = ({ children, sx }: { children: React.ReactNode; sx?: SxProps }) => {
  return (
    <Typography variant={'subtitle2'} sx={{ fontWeight: '400', ...sx }}>
      {children}
    </Typography>
  );
};

export default observer(FileListViewRow);
