import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material';
import { RadioButtonUncheckedRounded, TripOrigin } from '@mui/icons-material';
import { observer } from 'mobx-react';

import { COLOR_PRIMARY, COLOR_TABLE_HEADER, GRAY_COLORS } from '../../constants/colors';

import { OrganizationModel } from '../../models/OrganizationModel';
import Flex from '../utils/flex/Flex';
import { getTextOverflowEllipsisStyles } from '../typography/utils';
import { useStore } from '../../hooks/useStore';
import { PrimarySwitch } from '../Switch/PrimarySwitch';
import CopyTypography from '../CopyTypography/CopyTypography';
import ModelLogoPicture from './ModelLogoPicture';


interface ModelTableRowProps {
    model: OrganizationModel;
    index: number;
    openEditScreen: () => void;
}

const ModelTableRow = ({
    model,
    index,
    openEditScreen,
}: ModelTableRowProps) => {
    const [open, setOpen] = useState(false);
    const [displayShowMoreButton, setDisplayShowMoreButton] = useState(false);
    const [showMoreActive, setShowMoreActive] = useState(false);
    const [isPrimaryRowHovered, setIsPrimaryRowHovered] = useState(false);
    const [isCollapsedRowHovered, setIsCollapsedRowHovered] = useState(false);
    const [isDeleteModelModalOpen, setIsDeleteModelModalOpen] = useState(false);

    const {
        localizationStore: { i18next: i18n },
        modelStore: {
            defaultOrganizationModel,
            updateOrganizationModel
        },
    } = useStore();

    const { id } = model;

    const isDefaultModel = defaultOrganizationModel.id === id;
    const isDefaultRagModel = true;

    const ref = createRef<HTMLSpanElement>();

    const isEllipsisActive = (element: HTMLElement | null) => {
        if (!element) {
            return false;
        }

        return element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight;
    };

    // const handleIsMarkedAsDefaultChange = async (isMarkedAsDefault: boolean) => {
    //     setIsDirtyState(true);

    //     if (isDefaultModel && !isMarkedAsDefault) {
    //         await updateOrganizationModel({ ...model, isDefault: false }, true);
    //         openAddDefaultModelModal('modelsPage.modal.header.needAtLeastOneDefaultModel');
    //         return;
    //     }

    //     if (!isDefaultModel && isMarkedAsDefault) {
    //         await updateOrganizationModel({ ...defaultOrganizationModel, isDefault: false }, true);
    //     }

    //     await updateOrganizationModel({ ...model, isDefault: isMarkedAsDefault }, true);
    // };

    // const handleIsMarkedAsDefaultRagChange = async (isMarkedAsDefault: boolean) => {
    //     setIsDirtyState(true);

    //     if (isDefaultRagModel && !isMarkedAsDefault) {
    //         await updateOrganizationModel({ ...model, isDefaultFileModel: false }, false, true);
    //         openAddDefaultModelModal('modelsPage.modal.header.needAtLeastOneDefaultRagModel');
    //         return;
    //     }

    //     if (!isDefaultRagModel && isMarkedAsDefault) {
    //         await updateOrganizationModel({ ...defaultRagOrganizationModel, isDefaultFileModel: false }, false, true);
    //     }

    //     await updateOrganizationModel({ ...model, isDefaultFileModel: isMarkedAsDefault }, false, true);
    // };

    // const handleIsActiveChange = async (isActive: boolean) => {
    //     setIsDirtyState(true);

    //     if (isDefaultModel && !isActive) {
    //         openAddDefaultModelModal('modelsPage.modal.header.cantDisableDefaultModel');
    //         return;
    //     }

    //     if (isDefaultRagModel && !isActive) {
    //         openAddDefaultModelModal('modelsPage.modal.header.cantDisableDefaultRagModel');
    //         return;
    //     }

    //     await updateOrganizationModel({ ...model, isActive });
    // };

    useEffect(() => {
        setDisplayShowMoreButton(isEllipsisActive(ref.current));
    }, [ref]);

    const handleToggleButtonClick = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(!open);
    };

    return (
        <>
            <TableRow
                onMouseEnter={() => setIsPrimaryRowHovered(true)}
                onMouseLeave={() => setIsPrimaryRowHovered(false)}
                sx={[
                    (isPrimaryRowHovered || isCollapsedRowHovered) && { backgroundColor: COLOR_TABLE_HEADER },
                    { height: '82px', verticalAlign: 'top' },
                ]}
            >
                <StyledTableCell>
                    <Flex sx={{ height: '100%', alignItems: 'center' }}>
                        <ModelLogoPicture model={model} />
                        <Typography sx={{ fontWeight: '600' }}>{model.displayName}</Typography>
                    </Flex>
                </StyledTableCell>

                <StyledTableCell>
                    <Flex sx={{ height: '100%', alignItems: 'center' }}>
                        <ModelLogoPicture model={model} />
                        <Typography sx={{ fontWeight: '600' }}>{model.displayName}</Typography>
                    </Flex>
                </StyledTableCell>

                <StyledTableCell>
                    <Box>
                        <InlineTypography sx={showMoreActive ? {} : { ...getTextOverflowEllipsisStyles(3) }} ref={ref}>
                            {model.description}{' '}
                        </InlineTypography>
                        {(displayShowMoreButton || showMoreActive) && (
                            <InlineTypography
                                style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer', display: 'inline-block' }}
                                onClick={() => {
                                    setShowMoreActive(!showMoreActive);
                                }}
                            >
                                {showMoreActive
                                    ? i18n.t('adminPortal.tabs.models.readLess')
                                    : i18n.t('adminPortal.tabs.models.readMore')}
                            </InlineTypography>
                        )}
                    </Box>
                </StyledTableCell>

                <StyledTableCell>
                    <Box>
                        <InlineTypography sx={showMoreActive ? {} : { ...getTextOverflowEllipsisStyles(3) }} ref={ref}>
                            {model.description}{' '}
                        </InlineTypography>
                        {(displayShowMoreButton || showMoreActive) && (
                            <InlineTypography
                                style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer', display: 'inline-block' }}
                                onClick={() => {
                                    setShowMoreActive(!showMoreActive);
                                }}
                            >
                                {showMoreActive
                                    ? i18n.t('adminPortal.tabs.models.readLess')
                                    : i18n.t('adminPortal.tabs.models.readMore')}
                            </InlineTypography>
                        )}
                    </Box>
                </StyledTableCell>

                <StyledTableCell align={'right'}>
                    <Flex sx={{ justifyContent: 'flex-end' }}>
                        <IconButton
                            onClick={() => {
                                // setCurrentOrganizationModel(model);
                                openEditScreen();
                            }}
                        >
                            <ModeEditOutlineOutlinedIcon sx={{ width: '24px', height: '24px', color: GRAY_COLORS.GRAY_5 }} />
                        </IconButton>

                        <IconButton onClick={() => setIsDeleteModelModalOpen(true)}>
                            <DeleteOutlineOutlinedIcon sx={{ width: '24px', height: '24px', color: GRAY_COLORS.GRAY_5 }} />
                        </IconButton>
                    </Flex>
                </StyledTableCell>
            </TableRow>

            <TableRow
                onMouseEnter={() => setIsCollapsedRowHovered(true)}
                onMouseLeave={() => setIsCollapsedRowHovered(false)}
                sx={{ backgroundColor: isPrimaryRowHovered || isCollapsedRowHovered ? COLOR_TABLE_HEADER : 'inherit' }}
            >
                {open && (
                    <StyledTableCell sx={{ padding: 0 }} colSpan={6}>
                        <Collapse in={open} timeout='auto' sx={{ borderLeft: 'none', padding: '0 20px 0 10px' }}>
                            <Flex sx={{ padding: open ? '8px 0' : 0 }}>
                                {model.apiEndpoint && (
                                    <CopyTypography label={i18n.t('models.textField.apiEndpoint.label')} value={model.apiEndpoint} />
                                )}
                                {model.apiKey && (
                                    <CopyTypography
                                        label={i18n.t('models.textField.apiKey.label')}
                                        value={model.apiKey}
                                        sx={{ marginLeft: '40px' }}
                                    />
                                )}
                            </Flex>
                        </Collapse>
                    </StyledTableCell>
                )}
            </TableRow>

            {/* {isDeleteModelModalOpen &&
                <DeleteModelModal
                    isOpen={isDeleteModelModalOpen}
                    onClose={() => setIsDeleteModelModalOpen(false)}
                    onSave={async () => {
                        // await removeOne(model.id);
                        setIsDeleteModelModalOpen(false);
                    }} />
            } */}
        </>
    );
};

const InlineTypography = styled(Typography)`
  display: inline;
`;

const StyledTableCell = styled(TableCell)`
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  background: inherit !important;
  padding-top: 14px;
  padding-bottom: 12px;
`;

export default observer(ModelTableRow);
