import { ReactNode, useState, useEffect } from 'react';
import { Box, Stepper, Step, StepLabel, Typography, IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router';

import { Paths } from '../../constants/routes';

import CustomModal from '../modal/CustomModal';
import AskQuestions from '../Fortress/AskQuestions';
import { useStore } from '../../hooks/useStore';
import Button from '../buttons/Button';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const steps = ['Select files', 'Add questions'];

const SelectFortressModal = ({ isOpen, onClose }: ModalProps) => {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const {
    conversationStore: {
      createFortressSolution,
      fortressQuestions,
      fortressFiles,
      setIsBackBtnUsed,
      setAgentFiles,
      setFortressQuestions,
      isBackBtnUsed,
    },
  } = useStore();

  const handleNext = async () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSend = async () => {
    const fileNamesList = fortressFiles.map(file => file.key);

    const createFortressSolutionDto = {
      client_id: 'Just_A_Test_INC',
      solution_set: fortressQuestions.map(question => {
        const questionValue = question.value;
        const questionId = question.id;

        return {
          question_id: questionId,
          file_list: fileNamesList,
          question: questionValue,
          solution: [
            {
              name: 'query_text_start_2_list',
              class: 'QueryTextList',
              kwargs: {
                bucket_name: process.env.REACT_APP_AWS_DOCUMENTS_S3_BUCKET || `dev-avm-documents-addvaluemachine`,
                question: questionValue,
                file_list: fileNamesList,
                llm_kwargs: {
                  model_id: 'mistral.mixtral-8x7b-instruct-v0:1',
                  model_kwargs: {
                    max_tokens: 4000,
                    temperature: 0,
                    top_p: 0.9,
                    top_k: 200,
                  },
                },
                embedding_info: {
                  max_tokens_ref_text: 3000,
                  min_tokens_ref_text: 1,
                  text_concatenation_level: 'page',
                  embed_llm_kwargs: { model_id: 'amazon.titan-embed-text-v1' },
                  tok_llm_kwargs: {
                    model_id: 'mistral.mixtral-8x7b-instruct-v0:1',
                  },
                  text_concatenation_hierarchy: ['file_name', 'page', 'block', 'chunk', 'line', 'span'],
                },
                reference_retrieval_info: {
                  file_filter: null,
                  file_types: ['pdf', 'txt', 'doc', 'docx'],
                },
                reference_match_text: questionValue,
                reference_query:
                  'Human:does the following document in triple quotes provide information relevant to' +
                  ` this question: ${questionValue}  \n\n DOCUMENT:\n """{context}""". If it does not, reply no.` +
                  ' If it does, please summarize the relevant content\n\nAssistant: ',
                list_condense_instruct:
                  'This is a conversation between a human and a helpful assistant who answers a' +
                  'final question using only the information provided to it. \n\n Human: Please use the following' +
                  ` information in triple quotes to answer this question: ${questionValue} \n\n Information:` +
                  ' """{context}"""\n\n Assistant: ',
              },
            },
          ],
        };
      }),
    };

    setLoading(true);
    await createFortressSolution(createFortressSolutionDto);
    setLoading(false);
    onClose();
    setIsBackBtnUsed(false);
    navigate(`${Paths.FORTRESS_RESULT}`);
  };

  useEffect(() => {
    if (!isBackBtnUsed) {
      setFortressQuestions([{ id: '1', value: '' }]);
      setAgentFiles([]);
      setIsBackBtnUsed(false);
    }
  }, []);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      sx={{ flexDirection: 'column-reverse', alignItems: 'end', width: '600px', padding: '24px', gap: '12px' }}
      key={'Select-fortress'}
    >
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            <AskQuestions activeStep={activeStep} setLoading={setLoading} />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} loading={loading}>
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={activeStep === steps.length - 1 ? handleSend : handleNext} loading={loading}>
                {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
              </Button>
            </Box>
          </>
        )}
      </Box>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </CustomModal>
  );
};

export default observer(SelectFortressModal);
