import React, { useRef, useState } from 'react';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';

import DragAndDropIcon from '../../../assets/icons/drag-and-drop.svg';
import DragAndDropActiveIcon from '../../../assets/icons/drag-and-drop-active.svg';

import { useStore } from '../../../hooks/useStore';
import { getDocumentsKey, uploadDocumentSecurely } from '../../../utils/documentsS3';
import { COLOR_BLACK, COLOR_PRIMARY } from '../../../constants/colors';
import { observer } from 'mobx-react';
import { checkIfSupported } from '../../knowledgeBase/UploadFile';
import { fileStatus } from '../../../constants/fileStatus';
import { getFileExtensionFromName } from '../../../utils/getFileExtensionFromName';
import { convertDocxFileToPdf } from '../../../utils/convertDocxToPdf';
import { FileInputField } from '../../../models/Steps/Fields';
import {
  DragAndDropContainer,
  DragAndDropIconContainer,
  DragFileElement,
  Label,
  UploadButton,
} from '../../DragAndDropFileInput/StyledComponents';
import FileUploadCard from '../../DragAndDropFileInput/components/FileUploadCard';
import FileNotSupportedCard from '../../DragAndDropFileInput/components/FileNotSupportedRow';
import { checkIsConversationNeeded } from '../../../utils/checkIsConversationNeeded';

type WorkflowStepFileInputProps = {
  field: FileInputField;
};

export interface FileAndDocumentKey {
  file: File;
  documentKey: string;
}

const WorkflowStepFileInput = ({
  field: { supportedFileExt, s3BucketName, isMultiple, name: fieldName },
}: WorkflowStepFileInputProps) => {
  const {
    localizationStore: { i18next: i18n },
    fileStore: { createFile, convertToDocx },
    appState: { s3DocumentsApi },
    createWorkflowRunStore: { setWorkflowRunDataField, workflowRunData },
  } = useStore();

  const [files, setFiles] = useState<FileAndDocumentKey[]>([]);
  const [dragActive, setDragActive] = useState(false);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});

  const uploadFile = async (fileAndDocumentKey: FileAndDocumentKey) => {
    setIsUploadInProgress(true);
    setFiles(files => [...files, fileAndDocumentKey]);
    const createdFileEntry = await createFile({
      key: fileAndDocumentKey.documentKey,
      name: fileAndDocumentKey.file.name,
      status: fileStatus.UPLOADED,
    });

    if (checkIfSupported(fileAndDocumentKey.file)) {
      setWorkflowRunDataField(fieldName, [...(workflowRunData[fieldName] || []), createdFileEntry]);
    }

    const preSignedPutUrl = await s3DocumentsApi.generateDocumentsWriteUrl(
      fileAndDocumentKey.documentKey,
      fileAndDocumentKey.file.type
    );

    const status = await uploadDocumentSecurely(preSignedPutUrl, fileAndDocumentKey.file, {
      setProgress: (progress: number) => {
        setUploadProgress(prevState => ({ ...prevState, [fileAndDocumentKey.documentKey]: progress }));
      },
    });

    if (status === 200) {
      setIsUploadInProgress(false);
      setUploadProgress(prevState => ({ ...prevState, [fileAndDocumentKey.documentKey]: 100 }));
    }
  };

  const handleAddFiles = async (newFiles: File[]) => {
    const uploadPromises = newFiles.map(async file => {
      const extension = getFileExtensionFromName(file.name);

      if (checkIsConversationNeeded(extension)) {
        file = await convertDocxFileToPdf(file, convertToDocx);
      }
      return uploadFile({
        file: file,
        documentKey: getDocumentsKey(`${s3BucketName}`, file.name),
      });
    });

    await Promise.all(uploadPromises);
  };

  const removeFileByKey = (key: string) => {
    setFiles(prevState => prevState.filter(fileAndDocumentKey => fileAndDocumentKey.documentKey !== key));
    setWorkflowRunDataField(
      fieldName,
      workflowRunData[fieldName].filter((file: any) => file.key !== key)
    );

    setUploadProgress(prevState => {
      delete prevState[key];
      return prevState;
    });
  };

  const handleDrag = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    const isSupported = await checkIfFileTypeIsSupported(event?.dataTransfer?.files);
    if (isSupported) {
      await handleAddFiles([...event.dataTransfer.files]);
    } else {
      console.log('file not supported');
    }
  };

  const checkIfFileTypeIsSupported = async (files: any) => {
    const fileNames = Array.from(files).map((file: any) => file.name);

    const allSupported = fileNames.every(fileName => {
      const extension = `.${getFileExtensionFromName(fileName)}`;
      return supportedFileExt.includes(extension);
    });

    return allSupported;
  };

  const handleChange = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.files && event.target.files[0]) {
      await handleAddFiles([...event.target.files]);
    }
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  // TODO: change labels to match the config
  return (
    <>
      <DragAndDropContainer onDragEnter={handleDrag}>
        <input
          ref={inputRef}
          id={`input-file-upload-${fieldName}`}
          type={'file'}
          accept={supportedFileExt.join(', ')}
          style={{ display: 'none' }}
          multiple={isMultiple}
          onChange={handleChange}
          onClick={onInputClick}
        />
        <Label htmlFor={`input-file-upload-${fieldName}`} dragActive={dragActive}>
          <Stack sx={{ alignItems: 'center', gap: '16px' }}>
            <DragAndDropIconContainer
              src={dragActive ? DragAndDropActiveIcon : DragAndDropIcon}
              alt={'drag-and-drop-icon'}
            />
            <Typography style={{ display: 'inline-flex', gap: '4px', color: COLOR_BLACK, fontWeight: 500 }}>
              {i18n.t('knowledgeBase.files.dragAndDrop.label')}{' '}
              <UploadButton sx={{ color: COLOR_PRIMARY }}>
                {i18n.t('knowledgeBase.files.chooseFile.label')}
              </UploadButton>
            </Typography>
          </Stack>
        </Label>
        {dragActive && (
          <DragFileElement
            id={`drag-file-element-${fieldName}`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </DragAndDropContainer>

      {files.length > 0 && (
        <>
          {files.map((fileAndDocumentKey, index) => {
            return checkIfSupported(fileAndDocumentKey.file) ? (
              <FileUploadCard
                file={fileAndDocumentKey}
                progress={uploadProgress[fileAndDocumentKey.documentKey] || 0}
                removeFileByKey={removeFileByKey}
                key={`file-uploaded-${fieldName}-${index}`}
              />
            ) : (
              <FileNotSupportedCard
                file={fileAndDocumentKey}
                progress={uploadProgress[fileAndDocumentKey.documentKey] || 0}
                removeFileByKey={removeFileByKey}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default observer(WorkflowStepFileInput);
