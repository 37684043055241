import CloseIcon from '@mui/icons-material/Close';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { Box, FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { COLOR_MANDATORY, COLOR_TEXT_PRIMARY, GRAY_COLORS } from '../../constants/colors';

import { useStore } from '../../hooks/useStore';
import Button from '../buttons/Button';
import AddPromptModalAutocomplete from '../modal/AddPromptModal/components/AddPromptModalAutocomplete';
import { StyledContainer } from '../modal/CropImageModal';
import CustomModal from '../modal/CustomModal';
import Flex from '../utils/flex/Flex';
import FlexColumnStart from '../utils/flex/FlexColumnStart';
import FlexRowSpaceBetween from '../utils/flex/FlexRowSpaceBetween';
import TextInput from '../inputs/TextInput';
import { Team } from '../../models/Team';
import { toJS } from 'mobx';
import { KnowledgeBaseModel } from '../../models/KnowledgeBaseModel';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  editMode?: boolean;
  knowledgeBaseToEdit?: KnowledgeBaseModel;
}

const ErrorsContainer = styled.div`
  background: rgba(200, 30, 30, 0.15);
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 16px;
`;

const optionStyle = {
  borderRadius: '6px',
  border: '1px solid #E0E2E9',
  padding: '2px 12px 2px 0',
};
const CreateKnowledgeBaseModal = ({ isOpen, onClose, editMode, knowledgeBaseToEdit }: ModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    knowledgeBaseStore: { createKnowledgeBase, update },
    userStore: {
      userData: { teams },
    },
  } = useStore();

  const [privacy, setPrivacy] = useState('Private');
  const [name, setName] = useState('');
  const [selectedTeams, setSelectedTeams] = useState<Team[]>([]);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

const onCreateKnowledgeBase = async () => {
  try {
    setLoading(true);
    const knowledgeBaseData = {
      name: name,
      isPrivate: privacy === 'Private',
      teams: selectedTeams.map(team => ({ id: team.id })),
    };

    if (editMode && knowledgeBaseToEdit) {
      await update(knowledgeBaseToEdit.id, knowledgeBaseData);
    } else {
      await createKnowledgeBase(knowledgeBaseData);
    }
    onClose();
  } catch (error) {
    console.error('Error creating/updating knowledge base:', error);
  } finally {
    setLoading(false);
  }
};

  const handleChangeRadioValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPrivacy(value);
    if (value === 'team' && selectedTeams.length === 0) {
      setError('Please select at least one Knowledge Base');
    } else {
      setError('');
    }
  };

  useEffect(() => {
    if (editMode && knowledgeBaseToEdit) {
      setName(knowledgeBaseToEdit.name);
      setPrivacy(knowledgeBaseToEdit.isPrivate ? 'Private' : 'Shared');
      setSelectedTeams(knowledgeBaseToEdit.teams || []);
    }
  }, [editMode, knowledgeBaseToEdit]);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      sx={{ width: '500px', padding: '24px' }}
      key={`${editMode ? 'update' : 'create'}-knowledge-base-modal`}
    >
      <StyledContainer>
        <FlexRowSpaceBetween sx={{ width: '100%' }}>
          <Typography variant={'subtitle1'} fontWeight={'700'}>
            {editMode
              ? i18n.t('knowledgeBase.updateKnowledgeBase.title')
              : i18n.t('knowledgeBase.createKnowledgeBase.title')}
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </FlexRowSpaceBetween>

        <Typography variant={'body1'} sx={{ marginTop: '24px' }}>
          Name
        </Typography>

        <TextInput
          id="name"
          placeholder={i18n.t('knowledgeBase.textField.knowledgeBaseName.placeHolder')}
          name={'name'}
          value={name}
          error={i18n.t(error)}
          onChange={event => {
            setName(event.target.value);
          }}
          variant={'outlined'}
          type={'text'}
          testId={'create-knowledge-base-name'}
          sx={{
            marginBottom: '16px',
            '& > .MuiInputBase-root': { height: '48px !important' },
            '& .MuiInputLabel-root.Mui-error': { top: '-4px' },
            '& .MuiInputLabel-root': { top: '-4px' },
            '& .MuiInputLabel-shrink': { top: 0 },
          }}
        />

        <Box sx={{ margin: '24px 0', width: '100%' }}>
          <Typography variant={'body2'} fontWeight={'600'}>{`${i18n.t(
            'knowledgeBase.createKnowledgeBase.privacy.label'
          )}`}</Typography>

          <RadioGroup
            onChange={handleChangeRadioValue}
            aria-labelledby={'groupOption'}
            name={'groupOption'}
            key={'groupOption'}
            sx={{ marginLeft: '10px', marginTop: '8px' }}
            value={privacy}
          >
            <Flex sx={{ gap: '8px' }}>
              <FormControlLabel
                value={'Private'}
                control={<Radio checkedIcon={<TripOriginIcon />} />}
                label={'Private'}
                checked={privacy === 'Private'}
                sx={optionStyle}
              />
              <FormControlLabel
                value={'Shared'}
                control={<Radio checkedIcon={<TripOriginIcon />} />}
                label={'Shared'}
                checked={privacy === 'Shared'}
                sx={optionStyle}
              />
            </Flex>
          </RadioGroup>

          {privacy === 'Shared' && (
            <FlexColumnStart sx={{ marginTop: '16px', gap: '12px' }}>
              <Typography variant={'body2'} fontWeight={'600'}>
                Team(s)
              </Typography>
              <AddPromptModalAutocomplete
                key={'teams-autocomplete'}
                label={i18n.t('labels.teams')}
                options={teams || []}
                value={selectedTeams}
                setValue={value => {
                  setSelectedTeams(value);
                  setError('');
                }}
              />
            </FlexColumnStart>
          )}
          {error && (
            <ErrorsContainer>
              <Typography variant="subtitle2" sx={{ color: COLOR_MANDATORY }}>
                {error}
              </Typography>
            </ErrorsContainer>
          )}
        </Box>

        <Flex sx={{ justifyContent: 'flex-end', gap: '16px', width: '100%' }}>
          <Button
            onClick={onClose}
            variant={'outlined'}
            sx={{
              border: `1px solid ${GRAY_COLORS.GRAY_300} !important`,
              color: `${COLOR_TEXT_PRIMARY} !important`,
              width: 'fit-content',
              padding: '9px 16px !important',
            }}
          >
            {i18n.t('common.cancel.action')}
          </Button>

          <Button
            onClick={onCreateKnowledgeBase}
            sx={{
              width: 'fit-content',
              padding: '9px 16px !important',
            }}
            loading={loading}
          >
            {editMode ? i18n.t('common.edit.action') : i18n.t('common.create.action')}
          </Button>
        </Flex>
      </StyledContainer>
    </CustomModal>
  );
};

export default observer(CreateKnowledgeBaseModal);
