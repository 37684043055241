import { File } from './File';
import { Team } from './Team';

export interface KnowledgeBaseModel {
  id: string;
  name: string;
  status: string;
  teamId?: string;
  createdAt: Date;
  updatedAt: Date;
  deleted: boolean;
  isOrganization: boolean;
  isPrivate?: boolean;
  createdBy?: string;
  files: File[];
  teams?: Team[];
}

export const EMPTY_KNOWLEDGE_MODEL = {
  id: '',
  name: 'Organization',
  teamId: '',
  status: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  deleted: false,
  isOrganization: false,
  isPrivate: false,
  createdBy: '',
  files: [],
};

export interface CreateKnowledgeBaseDto {
  name: string;
  teamId?: string;
  isOrganization?: boolean;
  isPrivate?: boolean;
  teams?: { id: string }[];
  files?: File[];
}

export interface AddFilesToKnowledgeBaseDto {
  knowledgeBaseId: string;
  files: { id: string }[];
}
