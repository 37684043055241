import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { LocalStorageKeys } from '../constants/localStorageKeys';

import { UserApi } from '../api/UserApi';
import { SAMLAuthProvider, signOut, getRedirectResult } from 'firebase/auth';
import { auth } from '../firebase';
import { OrganizationApi } from '../api/OrganizationApi';
import { AuditLogsApi } from '../api/AuditLogsApi';
import { signInWithRedirect, signInWithPopup } from '@firebase/auth';
import { SocketStore } from './SocketStore';
import { getTenantFromHost, isTenantDomainSet } from '../utils/tenantUtils';
import { isMobileOrTablet } from '../utils/device';

export class AuthStore {
  userApi: UserApi;
  organizationApi: OrganizationApi;
  auditLogsApi: AuditLogsApi;
  socketStore: SocketStore;

  public accessToken: string = '';
  public tokenExpirationDate: string = '';

  private loading: boolean = true;
  public isUserLoggedIn: boolean = false;
  public firebaseUser: {
    firebaseDisplayName: string;
    firebaseProfilePicture: string;
  } = {
    firebaseDisplayName: '',
    firebaseProfilePicture: '',
  };
  private isAuthorized: boolean = true;
  private refreshToken: string = '';
  private signInProvider: string | undefined | null;

  constructor(
    userApi: UserApi,
    organizationApi: OrganizationApi,
    auditLogsApi: AuditLogsApi,
    socketStore: SocketStore
  ) {
    const token = localStorage.getItem(LocalStorageKeys.accessToken) || '';
    const tokenExpirationDate = localStorage.getItem(LocalStorageKeys.expirationTime) || '';
    const refreshToken = localStorage.getItem(LocalStorageKeys.refreshToken) || '';
    const signInProvider = localStorage.getItem(LocalStorageKeys.signInProvider) || '';

    this.accessToken = token;
    this.signInProvider = signInProvider;
    this.tokenExpirationDate = tokenExpirationDate;
    this.userApi = userApi;
    this.organizationApi = organizationApi;
    this.auditLogsApi = auditLogsApi;
    this.socketStore = socketStore;
    this.refreshToken = refreshToken;

    makeAutoObservable(this);
    autoBind(this);
  }

  async getProvider(email: string) {
    const { providerId } = email && (await this.organizationApi.getSignInProvider({ email }));
    return providerId && new SAMLAuthProvider(providerId);
  }

  async login(withRedirect: boolean = false) {
    const isMobile = isMobileOrTablet();
    const params = new URLSearchParams(document.location.search);
    let providerId = decodeURI(params.get('providerId') || '');
    let email = decodeURI(params.get('email') || '');
    if (!providerId) {
      console.log('providerId is not defined, trying email and tenant domain');
      providerId = email && (await this.organizationApi.getSignInProvider({ email })).providerId;
      if (!providerId) {
        const tenant = getTenantFromHost();
        const isTenantDomain = isTenantDomainSet();
        if (isTenantDomain && window?.location?.href && isMobile) {
          const redirectToOriginLink = window.location.href.replace(`${tenant}.`, '');
          console.log('providerId cannot be retrieved, redirecting to origin');
          window.location.replace(redirectToOriginLink);
        } else if (!isMobile && isTenantDomain) {
          providerId = (await this.organizationApi.getProviderByDomain({ domain: tenant })).providerId;
          if (!providerId) {
            const redirectToOriginLink = window.location.href.replace(`${tenant}.`, '');
            console.log('redirectToOriginLink ------------------->> ', redirectToOriginLink);
            console.log('providerId cannot be retrieved, redirecting to origin');
            window.location.replace(redirectToOriginLink);
          }
        } else {
          return;
        }
      }
    }
    const providerToUse = new SAMLAuthProvider(providerId);

    providerToUse.setCustomParameters({
      prompt: 'select_account',
    });

    try {
      if (withRedirect) {
        // Use redirect auth for mobile only
        await signInWithRedirect(auth, providerToUse);
      } else {
        // Use the popup auth method to allow the user to sign in with a pop-up window on desktop
        const result = await signInWithPopup(auth, providerToUse);
        const { token, expirationTime, signInProvider } = await result?.user?.getIdTokenResult();
        const { user } = result;
        const { email } = user || {};

        let firebaseDisplayName = '';
        let firebaseProfilePicture = '';
        for (const providerDataItem of user.providerData) {
          if (providerDataItem.displayName) {
            firebaseDisplayName = providerDataItem.displayName;
          }

          if (providerDataItem.photoURL) {
            firebaseProfilePicture = providerDataItem.photoURL;
          }
        }

        localStorage.setItem(LocalStorageKeys.expirationTime, expirationTime);
        localStorage.setItem(LocalStorageKeys.accessToken, token);
        signInProvider && localStorage.setItem(LocalStorageKeys.signInProvider, signInProvider);

        try {
          const dbUser = await this.userApi.getUserData();
          if (!dbUser) {
            console.log('User not found');
          }
        } catch (e) {
          localStorage.removeItem(LocalStorageKeys.accessToken);
          localStorage.removeItem(LocalStorageKeys.tenant);
          localStorage.removeItem(LocalStorageKeys.signInProvider);
          await this.logout();
          throw e;
        }

        runInAction(() => {
          this.accessToken = token;
          this.tokenExpirationDate = expirationTime;
          this.isUserLoggedIn = true;
          this.firebaseUser = {
            firebaseDisplayName,
            firebaseProfilePicture,
          };
          this.signInProvider = signInProvider;
          this.auditLogsApi.createAuditLog({ userEmail: email, entityType: 'login', status: 'allowed' });
          this.socketStore.connect(token);
        });
      }
    } catch (error) {
      console.log('error ------>', error);
      await this.auditLogsApi.createAuditLog({ userEmail: email, entityType: 'login', status: 'not-allowed' });
      throw error;
    }
  }

  async tenantRedirect(email: string) {
    const { hostname, protocol, port } = window.location;
    try {
      const { providerId, domain } = await this.organizationApi.getSignInProvider({ email });
      if (!providerId || !domain) {
        return;
      }
      // eslint-disable-next-line max-len
      const tenantUrl = `${protocol}//${domain}.${hostname}${port ? ':'.concat(port) : ''}/login?providerId=${encodeURI(
        providerId
      )}&email=${encodeURI(email)}`;

      window.location.replace(tenantUrl);
    } catch (error) {
      await this.auditLogsApi.createAuditLog({ userEmail: email, entityType: 'login', status: 'not-allowed' });
      console.log('error', error);
      throw error;
    }
  }

  async getRedirectResult(): Promise<{
    isLogged: boolean | null;
    shouldRedirectToTenant?: boolean;
    firebaseDisplayName?: string;
    firebaseProfilePicture?: string;
  }> {
    const isTenantDomain = isTenantDomainSet();
    if (!isTenantDomain) {
      // console.log('isTenantDomain ------------------->> ', isTenantDomain);
      return { isLogged: null, shouldRedirectToTenant: true };
    }
    try {
      const result = await getRedirectResult(auth);
      const { user = null } = result ? result : {};
      if (!user) {
        console.log('no user found return');
        return { isLogged: null }; // no user found just display the login
      }
      const { token, expirationTime, signInProvider } = await user?.getIdTokenResult();

      let firebaseDisplayName = '';
      let firebaseProfilePicture = '';
      for (const providerDataItem of user.providerData) {
        if (providerDataItem.displayName) {
          firebaseDisplayName = providerDataItem.displayName;
        }

        if (providerDataItem.photoURL) {
          firebaseProfilePicture = providerDataItem.photoURL;
        }
      }

      try {
        const dbUser = await this.userApi.getUserData();
        if (!dbUser) {
          console.log('User not found');
        }
      } catch (e) {
        localStorage.removeItem(LocalStorageKeys.accessToken);
        localStorage.removeItem(LocalStorageKeys.tenant);
        localStorage.removeItem(LocalStorageKeys.signInProvider);
        await this.logout();
        throw e;
      }

      runInAction(() => {
        this.accessToken = token;
        this.tokenExpirationDate = expirationTime;
        this.isUserLoggedIn = true;
        this.signInProvider = signInProvider;

        localStorage.setItem(LocalStorageKeys.expirationTime, expirationTime);
        localStorage.setItem(LocalStorageKeys.accessToken, token);

        this.socketStore.connect(token);

        signInProvider && localStorage.setItem(LocalStorageKeys.signInProvider, signInProvider);
      });
      await this.auditLogsApi.createAuditLog({ userEmail: user.email, entityType: 'login', status: 'allowed' });
      return { isLogged: true, firebaseDisplayName, firebaseProfilePicture };
    } catch (e) {
      console.log('error', e);
      return { isLogged: false };
    }
  }

  async updateToken() {
    try {
      const { token, expirationTime, signInProvider } = (await auth.currentUser?.getIdTokenResult(true)) || {};

      if (token && expirationTime) {
        runInAction(() => {
          this.accessToken = token;
          this.tokenExpirationDate = expirationTime;
          this.isUserLoggedIn = true;
          if (signInProvider) {
            this.signInProvider = signInProvider;
          }

          localStorage.setItem(LocalStorageKeys.expirationTime, expirationTime);
          localStorage.setItem(LocalStorageKeys.accessToken, token);
          signInProvider && localStorage.setItem(LocalStorageKeys.signInProvider, signInProvider);
        });
      } else {
        await this.logout();
      }
    } catch (e) {
      await this.logout();
    }
  }

  setLoader(loading: boolean = false) {
    this.loading = loading;
  }

  setAuthToken(token: string) {
    runInAction(() => {
      this.accessToken = token;

      localStorage.setItem(LocalStorageKeys.accessToken, token);
    });
  }

  setIsLogin(loginStatus: boolean) {
    runInAction(() => {
      this.isUserLoggedIn = loginStatus;
    });
  }

  async logout() {
    try {
      await this.auditLogsApi.createAuditLog({
        userEmail: auth?.currentUser?.email,
        entityType: 'logout',
        status: 'allowed',
      });
      await signOut(auth);

      runInAction(() => {
        localStorage.clear();
        this.tokenExpirationDate = '';
        this.accessToken = '';
      });
    } catch (error) {
      await this.auditLogsApi.createAuditLog({
        userEmail: auth?.currentUser?.email,
        entityType: 'logout',
        status: 'not-allowed',
      });
      console.log('error', error);
    }
  }

  handleAuthorization(isAuthorized: boolean) {
    this.isAuthorized = isAuthorized;
  }
}
