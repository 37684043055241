import { ApiConfig } from './ApiConfig';
import { AddFilesToKnowledgeBaseDto, CreateKnowledgeBaseDto, KnowledgeBaseModel } from '../models/KnowledgeBaseModel';

export class KnowledgeBaseApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  async findOne(id: string): Promise<KnowledgeBaseModel> {
    return await this.apiConfig.sendRequest('GET', `/knowledge-base/byId/${id}`);
  }

  async getAllKnowledgeBasesByOrganization() {
    return await this.apiConfig.sendRequest('GET', `/knowledge-base/improved`);
  }

  async getKnowledgeBaseByOrganization() {
    return await this.apiConfig.sendRequest('GET', `/knowledge-base/organization`);
  }

  async createKnowledgeBase(body: CreateKnowledgeBaseDto) {
    return await this.apiConfig.sendRequest('POST', '/knowledge-base', body);
  }

  async addFilesToKnowledgeBase(body: AddFilesToKnowledgeBaseDto) {
    return await this.apiConfig.sendRequest('POST', '/knowledge-base/addFilesToKnowledgeBase', body);
  }

  async updateKnowledgeBase(knowledgeBaseId: string, data: Partial<CreateKnowledgeBaseDto>) {
    return await this.apiConfig.sendRequest('PATCH', `/knowledge-base/${knowledgeBaseId}`, data);
  }

  async deleteKnowledgeBase(knowledgeBaseId: string) {
    return await this.apiConfig.sendRequest('DELETE', `/knowledge-base/${knowledgeBaseId}`);
  }

  async deleteFile(fileId: string, knowledgeBaseId: string) {
    return await this.apiConfig.sendRequest('DELETE', `/knowledge-base/file/${knowledgeBaseId}`, { fileId });
  }
}
