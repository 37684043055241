import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import MenuOpen from '@mui/icons-material/MenuOpen';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react-lite';

import { COLOR_MANDATORY, GRAY_COLORS, COLOR_WHITE } from '../../../constants/colors';

import { useStore } from '../../../hooks/useStore';
import Flex from '../../../components/utils/flex/Flex';
import TabPanel from '../../../components/tabs/TabPanel';
import HistoryTab from '../Tabs/HistoryTab/HistoryTab';
import SharedWithMeTab from '../Tabs/SharedWithMeTab/SharedWithMeTab';
import FlexRowCenter from '../../../components/utils/flex/FlexRowCenter';
import Typography from '@mui/material/Typography';

const NotificationBullet = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${COLOR_MANDATORY};
  display: flex;
  align-items: center;
  justify-content: center;
`;
interface HomePageTabsProps {
  activeTab: number;
  closeTabs: () => void;
  changeTab: (newValue: number) => void;
}

const HomePageTabs = ({ activeTab, changeTab, closeTabs }: HomePageTabsProps) => {
  const {
    localizationStore: { i18next: i18n },
    conversationStore: { newConversations },
  } = useStore();

  const tabs = [
    {
      title: i18n.t('sidebarMenu.history.action'),
      component: <HistoryTab />,
    },
    {
      title: i18n.t('sidebarMenu.sharedWithMe.action'),
      component: <SharedWithMeTab activeTab={activeTab} />,
      newConversations: newConversations,
    },
    // {
    //   title: i18n.t('sidebarMenu.prompts.action'),
    //   component: <PromptsTab />,
    // },
    // {
    //   title: i18n.t('sidebarMenu.knowledgeBase.action'),
    //   component: <>Knowledge base</>,
    // },
  ];

  return (
    <>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${GRAY_COLORS.GRAY_100}`,
        }}
      >
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => changeTab(newValue)}
          variant="scrollable"
          sx={{ maxWidth: '90%', border: 'none' }}
        >
          {tabs.map((tab: { title: string; component: React.ReactNode; newConversations?: number }, index: number) => (
            <Tab
              key={`home-page-tab-${index}`}
              label={
                <FlexRowCenter sx={{ gap: '8px' }}>
                  {tab.title}
                  {!!tab.newConversations && (
                    <NotificationBullet>
                      <Typography variant={'body2'} sx={{ color: COLOR_WHITE, fontSize: '10px' }}>
                        {tab.newConversations}
                      </Typography>
                    </NotificationBullet>
                  )}
                </FlexRowCenter>
              }
              id={`home-page-tab-${index}`}
              aria-controls={`home-page-tab-panel-${index}`}
              sx={{ padding: '16px' }}
            />
          ))}
        </Tabs>
        <IconButton style={{ marginRight: '16px' }} onClick={closeTabs}>
          <MenuOpen />
        </IconButton>
      </Flex>
      {tabs.map((tab: { title: string; component: React.ReactNode }, index: number) => (
        <TabPanel key={`tab_${index}`} value={activeTab} index={index} sx={{ flex: 1 }}>
          <Box sx={{ height: '100%' }}>{tab.component}</Box>
        </TabPanel>
      ))}
    </>
  );
};

export default observer(HomePageTabs);
