import React, { useState } from 'react';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import GridViewIcon from '@mui/icons-material/GridView';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { COLOR_ERROR_TEXT, COLOR_PRIMARY, GRAY_COLORS } from '../../../constants/colors';
import ShareIcon from '../../../assets/icons/share-blue.svg';

import Button from '../../../components/buttons/Button';
import OpenAIDetailsSection from './OpenAIDetailsSection';
import { useStore } from '../../../hooks/useStore';
import Flex from '../../../components/utils/flex/Flex';
import { getTextOverflowEllipsisStyles } from '../../../components/typography/utils';
import ShareChatMessage from './ConversationChat/Components/ShareChatMessage';
import IconButton from '@mui/material/IconButton';
import EditConversationTitleModal from '../../../components/modal/EditConversationTitleModal';

const fileButtonStyle = {
  border: `1px solid ${GRAY_COLORS.GRAY_300} !important`,
  color: `${COLOR_PRIMARY} !important`,
  width: 'fit-content',
  padding: '9px 16px !important',
};

interface ConversationPageHeaderProps {
  viewOnly?: boolean;
  loading: boolean;
  isModelDisabled: boolean;
  isKnowledgeBaseProcessing?: boolean;
}

const ConversationPageHeader = ({
  viewOnly,
  isModelDisabled,
  loading,
  isKnowledgeBaseProcessing,
}: ConversationPageHeaderProps) => {
  const {
    localizationStore: { i18next: i18n },
    conversationStore: {
      conversationSettingsState,
      currentConversation,
      toggleShowFilePreview,
      showFilePreview,
      uploadInProgress,
      isSecondaryModelSelected,
      secondaryConversationSettingsState,
      showEditConversationTitleModal,
      setShowEditConversationTitleModal,
    },
    uiStore: { isPromptsDrawerExpanded, toggleIsPromptsDrawerExpanded },
  } = useStore();

  const [showShareModal, setShowShareModal] = useState(false);

  const { modelDisplayName, accuracy, length } = conversationSettingsState;

  const { title, id, chats, files, extension, integration } = currentConversation;

  if (loading) {
    return <></>;
  }

  return (
    <Stack
      style={{
        position: 'sticky',
        padding: '24px 24px 0 24px',
        ...(isPromptsDrawerExpanded ? { opacity: 0.6, backgroundColor: GRAY_COLORS.GRAY_2 } : {}),
      }}
      onClick={() => toggleIsPromptsDrawerExpanded(false)}
    >
      <Stack style={{ gap: '16px' }}>
        {id ? (
          <>
            <Flex sx={{ alignItems: 'flex-end' }}>
              <Typography variant={'h5'} sx={{ fontSize: '16px', ...getTextOverflowEllipsisStyles(1) }}>
                {title || i18n.t('conversation.newConversation')}
              </Typography>

              {!isModelDisabled && (
                <IconButton onClick={() => setShowEditConversationTitleModal(true)}>
                  <EditOutlinedIcon sx={{ height: '22px', width: '22px' }} />
                </IconButton>
              )}
            </Flex>

            {isModelDisabled && (
              <Typography variant={'h5'} sx={{ fontSize: '14px', marginTop: '-16px', color: COLOR_ERROR_TEXT }}>
                {i18n.t('conversation.modelDisabled')}
              </Typography>
            )}

            {isKnowledgeBaseProcessing && (
              <Typography variant={'h5'} sx={{ fontSize: '14px', marginTop: '-16px', color: COLOR_ERROR_TEXT }}>
                {i18n.t('conversation.knowledgeBaseProcessing')}
              </Typography>
            )}
          </>
        ) : (
          <>
            <Typography variant={'h5'} sx={{ fontSize: '16px' }}>
              {i18n.t('home.newConversation.howCanIHelp')}
            </Typography>
          </>
        )}
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
          <Box sx={{ maxWidth: id ? '80%' : '100%', display: 'flex', alignItems: 'center' }}>
            <OpenAIDetailsSection
              model={modelDisplayName}
              accuracy={accuracy}
              length={length}
              extension={extension}
              renderButton={!id && !chats?.length && !isSecondaryModelSelected}
              integration={integration?.configName} // passing database connection name to show with model
            />

            {isSecondaryModelSelected && (
              <>
                <Typography variant={'subtitle2'} sx={{ margin: '0 8px' }}>
                  or
                </Typography>
                <OpenAIDetailsSection
                  model={secondaryConversationSettingsState.model}
                  accuracy={secondaryConversationSettingsState.accuracy}
                  length={secondaryConversationSettingsState.length}
                  renderButton={true}
                />
              </>
            )}
          </Box>

          <Flex sx={{ gap: '8px' }}>
            {!viewOnly &&
              id &&
              Array.isArray(files) &&
              files.length > 0 &&
              (showFilePreview ? (
                <Button
                  onClick={() => toggleShowFilePreview()}
                  endIcon={<KeyboardDoubleArrowRightIcon />}
                  disabled={uploadInProgress}
                  variant={'outlined'}
                  sx={fileButtonStyle}
                >
                  {'Files'}
                </Button>
              ) : (
                <Button
                  onClick={() => toggleShowFilePreview()}
                  startIcon={<GridViewIcon />}
                  disabled={uploadInProgress}
                  variant={'outlined'}
                  sx={fileButtonStyle}
                >
                  {'Files'}
                </Button>
              ))}
            {id && chats?.length > 0 && (
              <Button
                onClick={() => setShowShareModal(true)}
                disabled={uploadInProgress}
                variant={'outlined'}
                sx={fileButtonStyle}
                id="Share-Button"
              >
                <img src={ShareIcon} alt="share-button" />
              </Button>
            )}
          </Flex>
        </Flex>
      </Stack>

      <ShareChatMessage
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        heading={i18n.t('conversation.shareConversationModal.heading')}
        confirmButton={
          <Button onClick={() => {}} variant={'contained'} sx={{ width: 'fit-content', height: '40px !important' }}>
            {i18n.t('conversation.startNew')}
          </Button>
        }
      />

      <EditConversationTitleModal
        isOpen={showEditConversationTitleModal}
        onClose={() => {
          setShowEditConversationTitleModal(false);
        }}
      />
    </Stack>
  );
};

export default observer(ConversationPageHeader);
