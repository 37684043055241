import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Joi from 'joi';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';

import Button from '../../buttons/Button';
import Flex from '../../utils/flex/Flex';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useStore } from '../../../hooks/useStore';
import { LoadingSpinner } from '../../spinner/LoadingSpinner';
import { generalValidation } from '../../../utils/generalValidation';
import CustomModal from '../CustomModal';
import GeneralSettings from './components/GeneralSettings';
import TabPanel from '../../tabs/TabPanel';
import { OrganizationModel } from '../../../models/OrganizationModel';
import AdvancedSettings from './components/AdvancedSettings';
import { INITIAL_PROMPT } from '../../../models/Prompt';

interface AddPromptModalProps {
  isOpen: boolean;
  onClose: () => void;
  editMode?: boolean;
}

const AddPromptModal = ({ isOpen, onClose, editMode }: AddPromptModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    promptsStore: { updatePrompt, createPrompt, currentPrompt, setEmptyPrompt },
    modelStore: { findAllOrganizationModels, organizationModels },
    uiStore: { toggleIsPromptDetailsCardDisplayed },
    userStore: {
      userData: { teams },
    },
  } = useStore();

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDirtyState, setIsDirtyState] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState<any>([]);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
  });

  const [recommendedModelTemperature, setRecommendedModelTemperature] = useState<number>(0.7);
  const [recommendedModelLength, setRecommendedModelLength] = useState<number>(1024);
  const [selectedModel, setSelectedModel] = useState<OrganizationModel>();
  const [shareMethod, setShareMethod] = useState('private');

  const [formErrors, setFormErrors] = useState({
    title: '',
    content: '',
  });

  const schema = Joi.object().keys({
    title: Joi.string().messages({
      'string.empty': i18n.t('prompts.textField.error.titleRequired'),
    }),
    content: Joi.string().messages({
      'string.empty': i18n.t('prompts.textField.error.contentRequired'),
    }),
  });

  useEffect(() => {
    if (isOpen && !editMode) {
      toggleIsPromptDetailsCardDisplayed(false);
      setEmptyPrompt();
    }

    return () => {
      if (!editMode) {
        setEmptyPrompt();
      }
    };
  }, [isOpen]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let orgModels = organizationModels;
      if (!orgModels.length) {
        orgModels = await findAllOrganizationModels();
      }
      setFormData({
        title: currentPrompt.title,
        content: currentPrompt.content,
      });

      const recommendedModel = orgModels.find(orgModel => orgModel.id === currentPrompt.recommendedModel?.modelId);

      if (recommendedModel) {
        setSelectedModel(recommendedModel);
        setRecommendedModelLength(currentPrompt.recommendedModel?.maxTokens || 1024);
        setRecommendedModelTemperature(currentPrompt.recommendedModel?.temperature || 0.7);
      }

      if (editMode && currentPrompt !== INITIAL_PROMPT) {
        setSelectedTeams(currentPrompt.teams);
      }

      setLoading(false);
    })();
  }, [currentPrompt]);

  useEffect(() => {
    setShareMethod(selectedTeams.length ? 'shared' : 'private');
  }, [selectedTeams]);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setIsDirtyState(true);

    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
  };

  const onKeyUp = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      await addPrompt();
    }
  };

  const addPrompt = async () => {
    const errors = generalValidation(schema, formData);

    if (errors && typeof errors === 'object') {
      setFormErrors({
        ...formErrors,
        ...errors,
      });
      return;
    }

    await createPrompt(
      {
        title: formData.title,
        content: formData.content,
        teams: selectedTeams.map((selectedTeam: any) => {
          return { id: selectedTeam.id };
        }),
        ...(selectedModel && {
          recommendedModel: {
            modelId: selectedModel.id,
            modelVersion: selectedModel.modelVersion,
            modelProvider: selectedModel.providerName,
            displayName: selectedModel.displayName,
            maxTokens: recommendedModelLength,
            temperature: recommendedModelTemperature,
          },
        }),
      },
      true,
      true
    );

    toggleIsPromptDetailsCardDisplayed(false);
    onClose();
  };

  const editPrompt = async () => {
    const errors = generalValidation(schema, formData);

    if (errors && typeof errors === 'object') {
      setFormErrors({
        ...formErrors,
        ...errors,
      });
      return;
    }

    await updatePrompt(currentPrompt.id, {
      title: formData.title,
      content: formData.content,
      teams: selectedTeams.map((selectedTeam: any) => {
        return { id: selectedTeam.id };
      }),
      ...(selectedModel && {
        recommendedModel: {
          modelId: selectedModel.id,
          modelVersion: selectedModel.modelVersion,
          modelProvider: selectedModel.providerName,
          displayName: selectedModel.displayName,
          maxTokens: recommendedModelLength,
          temperature: recommendedModelTemperature,
        },
      }),
    });

    toggleIsPromptDetailsCardDisplayed(false);
    onClose();
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const tabs = [
    {
      title: i18n.t('prompts.modal.addPrompt.details'),
      component: (
        <GeneralSettings
          shareMethod={shareMethod}
          setShareMethod={setShareMethod}
          formData={formData}
          setFormData={setFormData}
          formErrors={formErrors}
          setIsDirtyState={setIsDirtyState}
          onKeyUp={onKeyUp}
          onChange={onChange}
          selectedTeams={selectedTeams}
          setSelectedTeams={setSelectedTeams}
          teams={teams || []}
        />
      ),
    },
    {
      title: 'Advanced Settings',
      component: (
        <AdvancedSettings
          setSelectedModel={setSelectedModel}
          selectedModel={selectedModel}
          temperature={recommendedModelTemperature}
          setTemperature={setRecommendedModelTemperature}
          length={recommendedModelLength}
          setLength={setRecommendedModelLength}
          setIsDirtyState={setIsDirtyState}
        />
      ),
    },
  ];

  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <Flex
        sx={{
          flexDirection: 'column',
          width: '600px',
          backgroundColor: COLOR_WHITE,
          padding: '24px',
          overflow: 'scroll',
          maxHeight: '90vh',
        }}
      >
        <Flex sx={{ flexDirection: 'column', flex: 1, overflow: 'auto' }}>
          <Typography variant={'h5'} sx={{ marginBottom: '16px' }}>
            {editMode ? i18n.t('prompts.modal.editPrompt.header') : i18n.t('prompts.modal.addPrompt.header')}
          </Typography>

          <Typography variant={'body2'} sx={{ marginBottom: '16px', color: GRAY_COLORS.GRAY_500 }}>
            {editMode ? i18n.t('prompts.modal.editPrompt.subHeader') : i18n.t('prompts.modal.addPrompt.subHeader')}
          </Typography>

          <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
            {tabs.map((tab: { title: string; component: React.ReactNode }, index: number) => (
              <Tab
                key={`admin-insights-tab-${index}`}
                label={tab.title}
                id={`admin-insights-tab-${index}`}
                aria-controls={`admin-insights-tab-panel-${index}`}
              />
            ))}
          </Tabs>

          {tabs.map((tab, index: number) => (
            <TabPanel key={`tab_${index}`} value={activeTab} index={index} sx={{ marginTop: '24px' }}>
              {tab.component}
            </TabPanel>
          ))}
        </Flex>

        <Flex sx={{ marginTop: '24px', justifyContent: 'flex-end' }}>
          <Box sx={{ marginRight: '16px' }}>
            <Button onClick={onClose} variant={'outlined'} sx={{ marginRight: '8px' }}>
              {i18n.t('common.cancel.action')}
            </Button>
          </Box>
          <Box>
            <Button onClick={editMode ? editPrompt : addPrompt} disabled={editMode && !isDirtyState}>
              {editMode ? 'Save Prompt' : 'Create Prompt'}
            </Button>
          </Box>
        </Flex>
      </Flex>
    </CustomModal>
  );
};

export default AddPromptModal;
