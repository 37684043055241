import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import { ResultDetailsContainer, StyledSpan } from './styledComponents';
import { useStore } from '../../../../hooks/useStore';

const WorkflowResultPageBanner = () => {
  const {
    workFlowStore: { selectedWorkflowRun, selectedWorkflow },
    localizationStore: { i18next: i18n },
  } = useStore();

  return (
    <ResultDetailsContainer>
      <Box>
        <Box>
          <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 700 }}>
            {i18n.t('Name : ')}
            <StyledSpan>
              {selectedWorkflowRun.variables.workflowRunName
                ? selectedWorkflowRun.variables.workflowRunName
                : selectedWorkflowRun.variables.name}
            </StyledSpan>
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 700 }}>
            {i18n.t('Status : ')}
            <StyledSpan>{selectedWorkflowRun.status}</StyledSpan>
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 700 }}>
            {i18n.t('Workflow : ')}
            <StyledSpan>{selectedWorkflowRun.workflow?.name}</StyledSpan>
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 700 }}>
            {i18n.t('Owner : ')}
            <StyledSpan>{selectedWorkflowRun.user?.email}</StyledSpan>
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 700 }}>
            {i18n.t('Created At : ')}
            <StyledSpan>{new Date(selectedWorkflowRun.completedAt).toDateString()}</StyledSpan>
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 700 }}>
            {i18n.t('Category : ')}
            <StyledSpan>{selectedWorkflowRun.workflow?.category}</StyledSpan>
          </Typography>
        </Box>
      </Box>
    </ResultDetailsContainer>
  );
};

export default observer(WorkflowResultPageBanner);
