import React, { useRef, useState } from 'react';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import { File as FileModel } from '../../models/File';

import DragAndDropIcon from '../../assets/icons/drag-and-drop.svg';
import DragAndDropActiveIcon from '../../assets/icons/drag-and-drop-active.svg';

import {
  DragAndDropContainer,
  DragAndDropIconContainer,
  DragFileElement,
  Label,
  UploadButton,
} from './StyledComponents';
import { useStore } from '../../hooks/useStore';
import { getDocumentsKey, uploadDocumentSecurely } from '../../utils/documentsS3';
import { COLOR_BLACK, COLOR_PRIMARY } from '../../constants/colors';
import { observer } from 'mobx-react';
import FileUploadRowItem from './components/FileUploadRowItem';
import AppTable from '../table/AppTable';
import FileNotSupportedCard from './components/FileNotSupportedRow';
import { checkIfSupported } from '../knowledgeBase/UploadFile';
import { S3Folders } from '../../constants/S3Folders';
import { fileStatus } from '../../constants/fileStatus';
import { getFileExtensionFromName } from '../../utils/getFileExtensionFromName';
import { convertDocxFileToPdf } from '../../utils/convertDocxToPdf';
import { checkIsConversationNeeded } from '../../utils/checkIsConversationNeeded';

type DragAndDropFileInputProps = {
  setFilesToAdd: React.Dispatch<React.SetStateAction<FileModel[]>>;
};

export interface FileAndDocumentKey {
  file: File;
  documentKey: string;
}

const DragAndDropFileInput = ({ setFilesToAdd }: DragAndDropFileInputProps) => {
  const {
    localizationStore: { i18next: i18n },
    fileStore: { createFile, convertToDocx },
    knowledgeBaseStore: { currentKnowledgeBase, setIsUploadInProgress },
    appState: { s3DocumentsApi },
  } = useStore();

  const [files, setFiles] = useState<FileAndDocumentKey[]>([]);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});

  const uploadFile = async (fileAndDocumentKey: FileAndDocumentKey) => {
    setIsUploadInProgress(true);
    setFiles(files => [...files, fileAndDocumentKey]);
    const createdFileEntry = await createFile({
      key: fileAndDocumentKey.documentKey,
      name: fileAndDocumentKey.file.name,
      status: fileStatus.UPLOADED,
    });

    if (checkIfSupported(fileAndDocumentKey.file)) {
      setFilesToAdd(currentFiles => [...currentFiles, createdFileEntry]);
    }

    const preSignedPutUrl = await s3DocumentsApi.generateDocumentsWriteUrl(
      fileAndDocumentKey.documentKey,
      fileAndDocumentKey.file.type
    );

    const status = await uploadDocumentSecurely(preSignedPutUrl, fileAndDocumentKey.file, {
      setProgress: (progress: number) =>
        setUploadProgress(prevState => ({ ...prevState, [fileAndDocumentKey.documentKey]: progress })),
    });

    if (status === 200) {
      setIsUploadInProgress(false);
    }
  };

  const handleAddFiles = async (newFiles: File[]) => {
    const uploadPromises = newFiles.map(async file => {
      const extension = getFileExtensionFromName(file.name);

      if (checkIsConversationNeeded(extension)) {
        file = await convertDocxFileToPdf(file, convertToDocx);
      }
      return uploadFile({
        file: file,
        documentKey: getDocumentsKey(`${S3Folders.knowledgeBases}/${currentKnowledgeBase.id}`, file.name),
      });
    });

    await Promise.all(uploadPromises);
  };

  const removeFileByKey = (key: string) => {
    setFiles(prevState => prevState.filter(fileAndDocumentKey => fileAndDocumentKey.documentKey !== key));
    setFilesToAdd(prevState => prevState.filter(file => file.key !== key));

    setUploadProgress(prevState => {
      delete prevState[key];
      return prevState;
    });
  };

  const handleDrag = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      await handleAddFiles([...event.dataTransfer.files]);
    }
  };

  const handleChange = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.files && event.target.files[0]) {
      await handleAddFiles([...event.target.files]);
    }
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const tableHeaderColumnNames = [
    {
      name: 'File',
      width: 20,
    },
    {
      name: 'Redact sensitive data?',
      width: 5,
    },
    {
      name: 'remove',
      width: 1,
    },
  ];

  return (
    <>
      <DragAndDropContainer onDragEnter={handleDrag}>
        <input
          ref={inputRef}
          id="input-file-upload"
          type={'file'}
          accept={'.pdf, .doc, .ppt, .docx, .pptx, image/*, .csv, .txt, .json, .xlsx, .xls'}
          style={{ display: 'none' }}
          multiple
          onChange={handleChange}
          onClick={onInputClick}
        />
        <Label htmlFor="input-file-upload" dragActive={dragActive}>
          <Stack sx={{ alignItems: 'center', gap: '16px' }}>
            <DragAndDropIconContainer
              src={dragActive ? DragAndDropActiveIcon : DragAndDropIcon}
              alt={'drag-and-drop-icon'}
            />
            <Typography style={{ display: 'inline-flex', gap: '4px', color: COLOR_BLACK, fontWeight: 500 }}>
              {i18n.t('knowledgeBase.files.dragAndDrop.label')}{' '}
              <UploadButton sx={{ color: COLOR_PRIMARY }}>
                {i18n.t('knowledgeBase.files.chooseFile.label')}
              </UploadButton>
            </Typography>
          </Stack>
        </Label>
        {dragActive && (
          <DragFileElement
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </DragAndDropContainer>

      {files.length > 0 && (
        <AppTable tableHeaderColumnNames={tableHeaderColumnNames}>
          {files.map(fileAndDocumentKey => {
            return checkIfSupported(fileAndDocumentKey.file) ? (
              <FileUploadRowItem
                setFilesToAddToKnowledgeBase={setFilesToAdd}
                file={fileAndDocumentKey.file}
                documentKey={fileAndDocumentKey.documentKey}
                progress={uploadProgress[fileAndDocumentKey.documentKey] || 0}
                removeFileByKey={removeFileByKey}
              />
            ) : (
              <FileNotSupportedCard
                file={fileAndDocumentKey}
                progress={uploadProgress[fileAndDocumentKey.documentKey] || 0}
                removeFileByKey={removeFileByKey}
              />
            );
          })}
        </AppTable>
      )}
    </>
  );
};

export default observer(DragAndDropFileInput);
