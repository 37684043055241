import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';

import { COLOR_BORDER_PRIMARY, COLOR_WHITE } from '../../constants/colors';

import FullHeightScreenWrapper from '../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import ConversationPageHeader from './components/ConversationPageHeader';
import MessageInput from '../../components/inputs/MessageInput';
import ConversationChat from './components/ConversationChat/ConversationChat';
import { useStore } from '../../hooks/useStore';
import { DEFAULT_SETTINGS, INITIAL_CONVERSATION } from '../../models/Conversation';
import { useLocation } from 'react-router-dom';
import { SocketEvents } from '../../constants/events/SocketEvents';
import SettingsSection from '../../components/cards/newConversationCard/SettingsSection';
import CompareAnswersFromDifferentModelsModal from '../../components/modal/CompareAnswersFromDifferentModelsModal/CompareAnswersFromDifferentModelsModal';
import MessageWasBlockedModal from '../../components/modal/CompareAnswersFromDifferentModelsModal/components/MessageWasBlockedModal';
import { OrganizationModel } from '../../models/OrganizationModel';
import { fileStatus } from '../../constants/fileStatus';
import PromptsGalleryDrawer from '../PromptsGalleryPage/PromptsGalleryDrawer';
import useSize from '../../hooks/useSize';

const PageWrapper = styled(FullHeightScreenWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${COLOR_BORDER_PRIMARY};
  padding: 0px;
`;

const MessageInputContainer = styled(Box)`
  padding: 24px;
  position: sticky;
  bottom: 0;
  background-color: ${COLOR_WHITE};
`;

const ConversationPage = () => {
  const { id } = useParams();
  const { state } = useLocation();

  const {
    localizationStore: { i18next: i18n },
    conversationStore: {
      getConversationById,
      setEmptyConversation,
      currentConversation,
      updateConversation,
      getSharedWithMe,
      getNewConversations,
      updateConversationModelAndProvider,
      secondaryConversationSettingsState,
      selectedPromptId,
      setPrimaryConversationModelSettings,
      setMessageInput,
    },
    knowledgeBaseStore: { findOneWithoutUpdatingStore },
    modelStore: { findAllOrganizationModelsAccessibleByUser, organizationModels, defaultOrganizationModel },
    promptsStore: { getConversationEmptyStatePrompts },
    socketStore: { joinRoom, subscribe, leaveRoom, unSubscribeAllListenersFromSingleEvent },
    uiStore: {
      isOpenAiSettingsSectionExpanded,
      isOpenAiCompareAnswersModalOpen,
      isMessageWasBlockedModalOpen,
      isPromptsDrawerExpanded,
      toggleIsPromptsDrawerExpanded,
    },
    conversationStore: { conversationSettingsState, promptModel },
    WorkSpaceStore: { getWorkSpaceSetting },
  } = useStore();

  const [loading, setLoading] = useState(false);
  const [isConversationModelDisabled, setIsConversationModelDisabled] = useState(false);
  const [isConversationKnowledgeBaseProcessing, setIsConversationKnowledgeBaseProcessing] = useState(false);

  const [options, setOptions] = useState<OrganizationModel[]>([]);
  const [primaryModelInitialValue, setPrimaryModelDefaultValue] = useState<OrganizationModel>();
  const [secondaryModelInitialValue, setSecondaryModelInitialValue] = useState<OrganizationModel>();

  const fetchConversation = async () => {
    if (id) {
      await getConversationById(id);
    }
  };

  const elementRef = useRef<HTMLElement>(null);
  const size = useSize(elementRef);

  useEffect(() => {
    if (currentConversation?.id && currentConversation.isNewConversation) {
      (async () => {
        await updateConversation({ isNewConversation: false });
        await getSharedWithMe();
        await getNewConversations();
      })();
    }
  }, [currentConversation]);

  useEffect(() => {
    if (currentConversation?.isStartedFromKnowledgeBase) {
      (async () => {
        const knowledgeBase = await findOneWithoutUpdatingStore(currentConversation.knowledgeBaseId);
        if (knowledgeBase.status !== fileStatus.DONE) {
          setIsConversationKnowledgeBaseProcessing(true);
        }
      })();
    }
  }, [currentConversation]);

  useEffect(() => {
    if (!currentConversation.id) {
      setIsConversationModelDisabled(false);
      return;
    }

    let shouldDisableConversation = true;

    organizationModels.forEach(organizationModel => {
      if (
        organizationModel.modelVersion === currentConversation.model &&
        organizationModel.providerName === currentConversation.providerName &&
        organizationModel.isActive
      ) {
        shouldDisableConversation = false;
      }
    });

    if (isConversationModelDisabled !== shouldDisableConversation) {
      setIsConversationModelDisabled(shouldDisableConversation);
    }
  }, [currentConversation, organizationModels]);

  useEffect(() => {
    toggleIsPromptsDrawerExpanded(false);
    const conversationRoom = `conversation-${id}`;

    (async () => {
      if (!id || id === INITIAL_CONVERSATION.id) {
        setLoading(true);
        setEmptyConversation();
        await getConversationEmptyStatePrompts();
        await getWorkSpaceSetting();
        await findAllOrganizationModelsAccessibleByUser();
        const allOrganizationModels = await findAllOrganizationModelsAccessibleByUser();
        const defaultModel = allOrganizationModels.find(model => model.isDefault === true);

        if (promptModel) {
          if (conversationSettingsState?.model) {
            setPrimaryConversationModelSettings({
              accuracy: conversationSettingsState?.accuracy as number,
              length: conversationSettingsState?.length,
              model: conversationSettingsState?.model as string,
              providerName: conversationSettingsState?.providerName as string,
              modelDisplayName: conversationSettingsState?.modelDisplayName as string,
            });
          }
        } else {
          setPrimaryConversationModelSettings({
            accuracy: DEFAULT_SETTINGS.accuracy,
            length: DEFAULT_SETTINGS.length,
            model: defaultModel?.modelVersion as string,
            providerName: defaultModel?.providerName as string,
            modelDisplayName: defaultModel?.displayName as string,
          });
        }

        setLoading(false);
        return;
      }

      joinRoom(conversationRoom);

      subscribe(SocketEvents.fileStatusChanged, fetchConversation);
      subscribe(SocketEvents.messageProcessed, fetchConversation);

      if (state?.dontRefresh) {
        window.history.replaceState({}, document.title);
        return;
      }

      setLoading(true);
      await findAllOrganizationModelsAccessibleByUser();
      setEmptyConversation();
      await getConversationById(id);
      setLoading(false);
    })();

    return () => {
      if (id) {
        toggleIsPromptsDrawerExpanded(false);
        leaveRoom(conversationRoom);
        unSubscribeAllListenersFromSingleEvent(SocketEvents.fileStatusChanged);
        unSubscribeAllListenersFromSingleEvent(SocketEvents.messageProcessed);
        setEmptyConversation();
        setMessageInput('');
      }
    };
  }, [id]);

  useEffect(() => {
    const enabledOrganizationModels = organizationModels.filter(organizationModel => {
      if (organizationModel.modelVersion === defaultOrganizationModel.modelVersion) {
        setPrimaryModelDefaultValue(organizationModel);
        if (!selectedPromptId) {
          updateConversationModelAndProvider(
            organizationModel.modelVersion,
            organizationModel.providerName,
            organizationModel.displayName
          );
        }
      }

      if (organizationModel.modelVersion === secondaryConversationSettingsState.model) {
        setSecondaryModelInitialValue(organizationModel);
      } else {
        setSecondaryModelInitialValue(defaultOrganizationModel);
      }

      return organizationModel.isActive;
    });

    setOptions(enabledOrganizationModels);
  }, [organizationModels, defaultOrganizationModel]);

  return (
    <PageWrapper
      sx={
        isConversationModelDisabled || isConversationKnowledgeBaseProcessing
          ? { opacity: 0.5, pointerEvents: 'none', position: 'relative' }
          : {}
      }
    >
      {isOpenAiSettingsSectionExpanded ? (
        <SettingsSection
          options={options}
          primaryModelInitialValue={primaryModelInitialValue}
          secondaryModelInitialValue={secondaryModelInitialValue}
        />
      ) : (
        <>
          <ConversationPageHeader
            loading={loading}
            isModelDisabled={isConversationModelDisabled}
            isKnowledgeBaseProcessing={isConversationKnowledgeBaseProcessing}
          />
          <ConversationChat
            loading={loading}
            isChatDisabled={isConversationModelDisabled || isConversationKnowledgeBaseProcessing}
          />
          {isPromptsDrawerExpanded && (
            <Box
              sx={{
                position: 'absolute',
                bottom: (size?.bottom || 0) + (size?.top || 0),
                height: '70%',
                width: '100%',
              }}
            >
              <PromptsGalleryDrawer />
            </Box>
          )}
          <MessageInputContainer ref={elementRef}>
            <MessageInput textAreaPlaceholder={i18n.t('conversation.input.placeholder')} options={options} />
          </MessageInputContainer>
        </>
      )}
      {isOpenAiCompareAnswersModalOpen && <CompareAnswersFromDifferentModelsModal />}
      {isMessageWasBlockedModalOpen && <MessageWasBlockedModal />}
    </PageWrapper>
  );
};

export default observer(ConversationPage);
