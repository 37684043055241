import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import { observer } from 'mobx-react-lite';

import { COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';

import FlexRowCenter from '../utils/flex/FlexRowCenter';
import { MoreIcon } from './utils';
import { useStore } from '../../hooks/useStore';
import { KnowledgeBaseModel } from '../../models/KnowledgeBaseModel';
import AppTable from '../table/AppTable';
import FlexRowSpaceBetween from '../utils/flex/FlexRowSpaceBetween';
import TextInput from '../inputs/TextInput';
import { filterObjectsByKeys } from '../../utils/filterObjectsByKeys';
import { useSearchParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import FileMenu from './FileMenu';
import DeleteModal from './DeleteModal';
import CreateKnowledgeBaseModal from './CreateKnowledgeBaseModal';
import DeleteKnowledgeBaseModal from './DeleteKnowledgeBaseModal';

const inputStyle = {
  width: '100%',
  marginRight: '20px',
  '.MuiInputBase-root': {
    height: '40px',
  },
  '.MuiOutlinedInput-input': {
    padding: '10px 14px',
    width: '100%',
  },
};

const KnowledgeBasesListView = () => {
  const {
    knowledgeBaseStore: { knowledgeBases, setCurrentKnowledgeBase },
    localizationStore: { i18next: i18n },
  } = useStore();

  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredKnowledgeBases, setFilteredKnowledgeBases] = useState(knowledgeBases);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [currentKnowledgeBaseInFileMenu, setCurrentKnowledgeBaseInFileMenu] = useState<KnowledgeBaseModel>();

  const handleClickRow = (e: React.MouseEvent<HTMLDivElement>, knowledgeBase: KnowledgeBaseModel) => {
    setSearchParams(params => {
      params.set('knowledgeBase', knowledgeBase.id);

      return params;
    });

    setCurrentKnowledgeBase(knowledgeBase);
  };

  const handleClickMore = (e: React.MouseEvent<HTMLButtonElement>, knowledgeBase: KnowledgeBaseModel) => {
    setCurrentKnowledgeBaseInFileMenu(knowledgeBase);
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;

    setSearchValue(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const tableHeaderColumnNames = [
    {
      name: 'Name',
      width: 20,
    },
  ];

  useEffect(() => {
    if (!searchValue) {
      setFilteredKnowledgeBases(knowledgeBases);
    }

    const newFilteredKnowledgeBases = filterObjectsByKeys(knowledgeBases, ['name'], searchValue);

    setFilteredKnowledgeBases(newFilteredKnowledgeBases);
  }, [searchValue, knowledgeBases]);

  return (
    <Container>
      <FlexRowSpaceBetween sx={{ marginTop: '24px' }}>
        <TextInput
          id="search-input"
          value={searchValue}
          onChange={onInputChange}
          placeholder={'Search'}
          name={'searchFilter'}
          variant={'outlined'}
          isSearchField
          sx={{ ...inputStyle }}
        />
      </FlexRowSpaceBetween>

      <AppTable
        style={{ marginTop: '24px', maxHeight: 'calc(100% - 92px)' }}
        tableHeaderColumnNames={tableHeaderColumnNames}
      >
        {filteredKnowledgeBases?.map(item => (
          <KnowledgeBaseRow
            key={`folder-${item.id}`}
            onClick={e => {
              handleClickRow(e, item);
            }}
          >
            <FlexRowCenter>
              <FolderOpenOutlinedIcon sx={{ marginRight: '8px', color: GRAY_COLORS.GRAY_6 }} />
              {item.name}
            </FlexRowCenter>
            {!item.isOrganization && (
              <IconButton
                onClick={e => handleClickMore(e, item)}
                sx={{
                  width: '20px',
                  height: '20px',
                  '&:hover': {
                    backgroundColor: 'inherit',
                  },
                }}
              >
                <MoreIcon sx={{ width: '20px', height: '20px' }} />
              </IconButton>
            )}
          </KnowledgeBaseRow>
        ))}
      </AppTable>

      <FileMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        options={[
          {
            name: i18n.t('knowledgeBase.menu.edit'),
            icon: <EditIcon sx={{ color: GRAY_COLORS.GRAY_6 }} />,
            onClick: () => {
              setShowUpdateModal(true);
            },
          },
          {
            name: i18n.t('knowledgeBase.menu.delete'),
            icon: <DeleteIcon sx={{ color: GRAY_COLORS.GRAY_6 }} />,
            onClick: () => setShowDeleteModal(true),
            itemSx: { borderTop: `1px solid ${GRAY_COLORS.GRAY_2}` },
          },
        ]}
      />

      <CreateKnowledgeBaseModal
        knowledgeBaseToEdit={currentKnowledgeBaseInFileMenu}
        editMode={true}
        isOpen={showUpdateModal}
        onClose={() => {
          setShowUpdateModal(false);
          setAnchorEl(null);
        }}
      />
      <DeleteKnowledgeBaseModal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setAnchorEl(null);
        }}
        knowledgeBaseToDelete={currentKnowledgeBaseInFileMenu}
      />
    </Container>
  );
};

const Container = styled(Box)`
  overflow: hidden;
  height: calc(100% - 40px);
`;

const KnowledgeBaseRow = styled(Box)`
  background: ${COLOR_WHITE};
  padding: 13px 16px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${GRAY_COLORS.GRAY_2};

  &:hover {
    background: #edf3fe;
  }
`;

export default observer(KnowledgeBasesListView);
