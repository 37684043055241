import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { COLOR_BACKGROUND, GRAY_COLORS } from '../../constants/colors';
import { SIZES_SMALL } from '../../constants/sizes';

import AgentTable from './components/AgentTable';
import { useStore } from '../../hooks/useStore';

const AgentsPage = () => {
  const [isEditScreenOpen, setIsEditScreenOpen] = useState(false);
  const [isDirtyState, setIsDirtyState] = useState(false);
  const [isAddDefaultModelModalOpen, setIsAddDefaultModelModalOpen] = useState(false);
  const [headerText, setHeaderText] = useState('');

  const {
    modelStore: { organizationModels },
  } = useStore();

  return (
    <>
      <ModelsPageContainer>
        <AgentTable
          setIsDirtyState={setIsDirtyState}
          setHeaderText={setHeaderText}
          setIsAddDefaultModelModalOpen={setIsAddDefaultModelModalOpen}
          openEditScreen={() => {
            setIsEditScreenOpen(true);
          }}
        />
      </ModelsPageContainer>
    </>
  );
};

const ModelsPageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  background: ${COLOR_BACKGROUND};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  height: 100%;
  margin: 0;
  overflow: auto;

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

export default observer(AgentsPage);
