import React, { SyntheticEvent, useState, useRef } from 'react';
import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';

import { COLOR_PRIMARY, GRAY_COLORS } from '../../../constants/colors';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { BoxProps } from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import agentAvatarLogo from './../../../config/addvaluemachine/agentAvatarLogo.svg';
import uploadIcon from './../../../config/addvaluemachine/uploadIcon.svg';
import uploadingFile from './../../../config/addvaluemachine/uploadingFile.svg';

import FlexRowSpaceBetween from '../../../components/utils/flex/FlexRowSpaceBetween';
import { Headline1 } from '../../../components/typography/Headlines';
import Button from '../../../components/buttons/Button';
import { useStore } from '../../../hooks/useStore';
import { PromptsGalleryContentContainer } from '../styles';

import { OrganizationModel } from '../../../models/OrganizationModel';
import { ConversationSettings, DEFAULT_SETTINGS } from '../../../models/Conversation';
import { Paths } from '../../../constants/routes';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Flex from '../../../components/utils/flex/Flex';
import FeedbackForm from '../../../components/feedbackModal/FeedbackModal';

interface AgentAddFormProps {
  containerStyles: SxProps;
  setIsAddPromptModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeletePromptModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isTableDisplayedInDrawerMode?: boolean;
  options: OrganizationModel[];
  primaryModelInitialValue: OrganizationModel | undefined;
}

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => <FormControlLabel {...props} />)(
  ({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
      color: theme.palette.primary.main,
    },
  })
);

function MyFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

const AgentAddForm = ({
  containerStyles,
  setIsAddPromptModalOpen,
  setIsEditMode,
  setIsDeletePromptModalOpen,
  isTableDisplayedInDrawerMode,
  primaryModelInitialValue,
  options,
}: AgentAddFormProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const [showFeedback, setShowFeedback] = useState(false);
  const handleCloseFeedback = () => {
    setShowFeedback(false);
  };
  const [primaryModelSettings, setPrimaryModelSettings] = useState<ConversationSettings>({
    ...DEFAULT_SETTINGS,
    model: primaryModelInitialValue ? primaryModelInitialValue.modelVersion : DEFAULT_SETTINGS.model,
    providerName: primaryModelInitialValue ? primaryModelInitialValue.providerName : DEFAULT_SETTINGS.providerName,
  });

  const fileInput = useRef();

  const handleSubmit = () => {
    // event.preventDefault();
    // alert(`Selected file - ${fileInput.current.files[0].name}`);
  };

  const {
    localizationStore: { i18next: i18n },
    promptsStore: { userPrompts, userTeamsPrompts },
  } = useStore();
  const changeTab = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleOnCancel = () => {
    navigate(`${Paths.AGENT_LIST}`);
  };

  const [model, setModel] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setModel(event.target.value);
  };

  return (
    <PromptsGalleryContentContainer sx={containerStyles}>
      <FlexRowSpaceBetween sx={{ marginBottom: '28px' }}>
        <Headline1>{i18n.t('agentAdd.header')}</Headline1>
        <Box>
          <Button
            variant={'outlined'}
            sx={{
              marginRight: '8px',
              width: 'fit-content',
              borderColor: `${GRAY_COLORS.GRAY_2} !important`,
              color: `${COLOR_PRIMARY} !important`,
            }}
            onClick={handleOnCancel}
          >
            {i18n.t('agentList.button.cancel')}
          </Button>
          <Button
            // onClick={handleOnClick}
            sx={{
              padding: '9px 16px !important',
              height: '40px !important',
              width: 'fit-content',
              fontWeight: 400,
            }}
          >
            {i18n.t('agentAdd.button.saveNewAgent')}
          </Button>
        </Box>
      </FlexRowSpaceBetween>

      <FormControl sx={{ m: 1, width: '100%' }}>
        <Typography variant={'body2'} sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
          {`${i18n.t('agentAdd.form.selectModel')}`}
        </Typography>
        <Select value={model} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
          <MenuItem value="">GPT-4</MenuItem>
          <MenuItem value="Titan (BR)">Titan (BR)</MenuItem>
          <MenuItem value="Command">Command</MenuItem>
          <MenuItem value="GPT-3.5 Turbo">GPT-3.5 Turbo</MenuItem>
          <MenuItem value="GPT-4-32k">GPT-4-32k</MenuItem>
          <MenuItem value="Embedding Model">Embedding Model</MenuItem>
          <MenuItem value="Llama2 7B File">Llama2 7B File</MenuItem>
        </Select>

        <br />

        <Typography variant={'body2'} sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
          {`${i18n.t('agentAdd.form.agentName')}`}
        </Typography>
        <OutlinedInput
          id="outlined-adornment-weight"
          // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            'aria-label': 'weight',
          }}
        />

        <br />

        <Typography variant={'body2'} sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
          {`${i18n.t('agentAdd.form.personaDescription')}`}
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder={`${i18n.t('agentAdd.form.personaDescription.placeHolder')}`}
        />

        <br />

        <Typography variant={'body2'} sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
          {`${i18n.t('agentAdd.form.agentAvatar')}`}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            height: 116,
            borderRadius: 1,
          }}
        >
          <Box>
            <img
              src={agentAvatarLogo}
              alt="agentAvatarLogo"
              style={{
                height: '90px',
                alignItems: 'left',
                paddingRight: '15px',
              }}
            />
          </Box>

          <Box>
            <Button
              variant="outlined"
              startIcon={<FileUploadIcon />}
              sx={{
                padding: '4px 8px',
                fontSize: '0.7rem',
              }}
            >
              {i18n.t('agentAdd.form.agentAvatar.upload')}
            </Button>
            <Typography variant={'body2'} sx={{ marginTop: '8px', marginBottom: '24px', color: GRAY_COLORS.GRAY_7 }}>
              {i18n.t('agentAdd.form.agentAvatar.description')}
            </Typography>
          </Box>
        </Box>

        <FlexRowSpaceBetween sx={{ marginBottom: '28px' }}>
          <Typography variant={'body2'} sx={{ fontWeight: 'bold' }}>
            {`${i18n.t('agentAdd.form.integrations')}`}
          </Typography>
          <Typography
            variant={'body2'}
            sx={{
              fontWeight: 'bold',
              marginBottom: '8px',
              textDecoration: 'underline',
              color: COLOR_PRIMARY,
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowFeedback(true);
            }}
          >
            {`${i18n.t('agentAdd.form.addIntegrations')}`}
          </Typography>
          {showFeedback && (
            <FeedbackForm
              showFeedback={showFeedback}
              handleCloseFeedback={handleCloseFeedback}
              title={`${i18n.t('agentAdd.form.addIntegrations.title')}`}
              subtitle={`${i18n.t('agentAdd.form.addIntegrations.subtitle')}`}
              typeOfModel={`${i18n.t('agentAdd.form.addIntegrations.typeOfModel')}`}
              saveBtn={`${i18n.t('agentAdd.form.addIntegrations.saveBtn')}`}
            />
          )}
        </FlexRowSpaceBetween>
        <FormGroup>
          <FormControlLabel control={<Checkbox defaultChecked />} label="Sharepoint" />
          <FormControlLabel control={<Checkbox />} label="AI Initiated Phonecall" />
          <FormControlLabel control={<Checkbox />} label="Web Search" />
          <FormControlLabel control={<Checkbox />} label="Search Press Releases" />
          <FormControlLabel control={<Checkbox />} label="PubMed" />
          <FormControlLabel control={<Checkbox />} label="Look Up Files Added" />
          <FormControlLabel control={<Checkbox />} label="Search US SEC Filings" />
          <FormControlLabel control={<Checkbox />} label="Lookup Wikipedia" />
          {/* <FormControlLabel required control={<Checkbox />} label="Lookup Wikipedia"/> */}
          {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
        </FormGroup>

        <br />

        <Box>
          <Typography variant={'body2'} sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
            {`${i18n.t('agentAdd.form.uploadFiles')}`}
          </Typography>
          <Typography variant={'body2'} sx={{ marginTop: '8px', marginBottom: '24px', color: GRAY_COLORS.GRAY_7 }}>
            {i18n.t('agentAdd.form.uploadFiles.description')}
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 200,
            border: '2px dotted gray',
            borderRadius: 3,
            p: 2,
            gap: 2,
          }}
        >
          <input
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            // ref={fileInput}
          />
          <label htmlFor="contained-button-file">
            <img
              src={uploadIcon}
              alt="uploadIcon"
              style={{
                height: '30px',
              }}
            />
          </label>
          <Box sx={{ display: 'flex', marginBottom: '8px' }}>
            <Typography variant={'body2'} sx={{ fontWeight: 'bold', mr: 1 }}>
              {`${i18n.t('agentAdd.form.uploadFiles.dragDrop')}`}
            </Typography>
            <Typography variant={'body2'} sx={{ fontWeight: 'bold', color: 'blue', textDecoration: 'underline' }}>
              {`${i18n.t('agentAdd.form.uploadFiles.dragDrop.choseFile')}`}
            </Typography>
          </Box>
        </Box>
        <Typography variant={'body2'} sx={{ marginTop: '8px', marginBottom: '24px', color: GRAY_COLORS.GRAY_7 }}>
          {i18n.t('agentAdd.form.uploadFiles.support')}
        </Typography>

        <br />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: 1,
            borderColor: 'divider',
            backgroundColor: 'grey.200',
            borderRadius: 1,
            p: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ mr: 2 }}>
              <img
                src={uploadIcon}
                alt="uploadIcon"
                style={{
                  height: '30px',
                }}
              />
            </Box>
            <Box>
              <Typography variant={'body2'}>{`${i18n.t('agentAdd.form.uploadFiles.uploadedFileName')}`}</Typography>
              <Typography variant={'body2'}>{`${i18n.t('agentAdd.form.uploadFiles.uploadedFileProgress')}`}</Typography>
            </Box>
          </Box>

          <CircularProgress />
        </Box>

        <br />

        <Typography variant={'body2'} sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
          {`${i18n.t('agentAdd.form.createPublicLink')}`}
        </Typography>

        <RadioGroup name="use-radio-group" defaultValue="first">
          <MyFormControlLabel
            value="first"
            label={`${i18n.t('agentAdd.form.createPublicLink.yes')}`}
            control={<Radio />}
          />
          <MyFormControlLabel
            value="second"
            label={`${i18n.t('agentAdd.form.createPublicLink.no')}`}
            control={<Radio />}
          />
        </RadioGroup>
      </FormControl>
    </PromptsGalleryContentContainer>
  );
};

export default observer(AgentAddForm);
