import Button from '../../buttons/Button';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../../hooks/useStore';

interface StepperButtonGroupProps {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  loading?: boolean;
  onSubmit?: () => void;
}

const StepperButtonGroup = ({ currentStep, setCurrentStep, loading, onSubmit }: StepperButtonGroupProps) => {
  const {
    workFlowStore: { selectedWorkflow },
    localizationStore: { i18next: i18n },
  } = useStore();

  const handleNext = () => {
    if (currentStep < selectedWorkflow.steps.length - 1) {
      console.log('<< ----- AICISA ----- >>');
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep + -1);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
      <Button disabled={currentStep === 0} onClick={handlePrevious} sx={{ mr: 1 }} loading={loading}>
        {i18n.t('common.back.action')}
      </Button>
      <Box sx={{ flex: '1 1 auto' }} />
      <Button
        onClick={currentStep === selectedWorkflow?.steps?.length - 1 && onSubmit ? onSubmit : handleNext}
        loading={loading}
        type="submit"
      >
        {i18n.t(currentStep === selectedWorkflow?.steps?.length - 1 ? 'common.submit' : 'common.next.action')}
      </Button>
    </Box>
  );
};

export default observer(StepperButtonGroup);
