import React, { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';

import { GRAY_COLORS } from '../../../constants/colors';

import { useStore } from '../../../hooks/useStore';
import SelectModelDetails from './SelectModelDetails';
import { ConversationSettings, DEFAULT_SETTINGS } from '../../../models/Conversation';
import Button from '../../buttons/Button';
import Flex from '../../utils/flex/Flex';
import { OrganizationModel } from '../../../models/OrganizationModel';

interface SettingsSectionProps {
  options: OrganizationModel[];
  primaryModelInitialValue: OrganizationModel | undefined;
  secondaryModelInitialValue: OrganizationModel | undefined;
}

const SettingsSection = ({ options, primaryModelInitialValue, secondaryModelInitialValue }: SettingsSectionProps) => {
  const {
    conversationStore: {
      isSecondaryModelSelected,
      setIsSecondaryModelSelected,
      setPrimaryConversationModelSettings,
      setSecondaryConversationModelSettings,
      conversationSettingsState,
      secondaryConversationSettingsState,
    },
    uiStore: { toggleOpenAiSettingsSection },
  } = useStore();

  const [primaryModelSettings, setPrimaryModelSettings] = useState<ConversationSettings>({
    ...conversationSettingsState,
    model: conversationSettingsState ? conversationSettingsState.model : DEFAULT_SETTINGS.model,
    providerName: conversationSettingsState ? conversationSettingsState.providerName : DEFAULT_SETTINGS.providerName,
    modelDisplayName: conversationSettingsState?.modelDisplayName ?? DEFAULT_SETTINGS.modelDisplayName,
    accuracy: conversationSettingsState?.accuracy ?? DEFAULT_SETTINGS?.accuracy,
    length: conversationSettingsState?.length ?? DEFAULT_SETTINGS?.length,
  });
  const [secondaryModelSettings, setSecondaryModelSettings] = useState<ConversationSettings>({
    ...secondaryConversationSettingsState,
    model: secondaryConversationSettingsState ? secondaryConversationSettingsState.model : DEFAULT_SETTINGS.model,
    providerName: secondaryConversationSettingsState
      ? secondaryConversationSettingsState.providerName
      : DEFAULT_SETTINGS.providerName,
  });

  return (
    <Box sx={{ padding: '24px' }}>
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ lineHeight: '24px', fontSize: '18px', fontWeight: 700 }}>Settings</Typography>
        <IconButton onClick={() => toggleOpenAiSettingsSection(false)}>
          <CloseIcon />
        </IconButton>
      </Flex>
      <SelectModelDetails
        defaultModel={primaryModelInitialValue}
        options={options}
        modelSettings={primaryModelSettings}
        updateModelState={(newConversationSetting: ConversationSettings) => {
          setPrimaryModelSettings(newConversationSetting);
        }}
      />

      {isSecondaryModelSelected && (
        <SelectModelDetails
          defaultModel={secondaryModelInitialValue}
          options={options}
          modelSettings={secondaryModelSettings}
          updateModelState={(newConversationSetting: ConversationSettings) => {
            setSecondaryModelSettings(newConversationSetting);
          }}
          isSecondaryModel={true}
        />
      )}

      <Flex sx={{ justifyContent: 'space-between', marginTop: '24px' }}>
        {isSecondaryModelSelected ? (
          <Box />
        ) : (
          <Flex>
            <Button
              variant="outlined"
              sx={{ height: '40px', width: 'fit-content' }}
              startIcon={<AddIcon />}
              onClick={() => {
                setIsSecondaryModelSelected(true);
              }}
            >
              Add Second Model
            </Button>
            <Typography
              variant={'body2'}
              sx={{ color: GRAY_COLORS.GRAY_500, marginTop: '4px', marginLeft: '16px', maxWidth: '500px' }}
            >
              Optionally, you can select a secondary AI model to compare answers and determine the best option for your
              conversation.
            </Typography>
          </Flex>
        )}
        <Button
          sx={{ height: '40px', width: 'fit-content' }}
          onClick={() => {
            if (primaryModelSettings) {
              setPrimaryModelSettings(primaryModelSettings);
              setPrimaryConversationModelSettings(primaryModelSettings);
            }

            if (secondaryModelSettings) {
              setSecondaryModelSettings(secondaryModelSettings);
              setSecondaryConversationModelSettings(secondaryModelSettings);
            }

            toggleOpenAiSettingsSection();
          }}
        >
          Apply
        </Button>
      </Flex>
    </Box>
  );
};

export default observer(SettingsSection);
