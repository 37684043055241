import axios, { AxiosRequestConfig, Method } from 'axios';
import { LocalStorageKeys } from '../constants/localStorageKeys';
import { auth } from '../firebase';

export class ApiConfig {
  api: string;
  appVersion: string;

  constructor(api: string, releaseVersion: string) {
    this.api = api;
    this.appVersion = releaseVersion;

    this.addInterceptors();
  }

  addInterceptors() {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error?.response?.status === 403 || error?.response?.status === 401) {
          // Redirect to login page if token has expired
          localStorage.clear();
          window.location.href = '/login?loggedOut=true';
        }
        return Promise.reject(error);
      }
    );
  }

  get(): any {
    // grab the config for the app
  }

  async sendRequest(
    method: Method,
    endpoint: string,
    data?: any,
    otherConfig?: Partial<AxiosRequestConfig>
  ): Promise<any> {
    const url = `${this.api}${endpoint}`;
    const token = (localStorage.getItem(LocalStorageKeys.accessToken) as string) || {};

    const config: AxiosRequestConfig = {
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      ...(otherConfig || {}),
    };
    try {
      const response = await axios(config);
      return response.data;
    } catch (e: any) {
      if (e.response?.status === 401) {
        const { token, expirationTime, signInProvider } = (await auth.currentUser?.getIdTokenResult(true)) || {};
        if (token && expirationTime) {
          localStorage.setItem(LocalStorageKeys.expirationTime, expirationTime);
          localStorage.setItem(LocalStorageKeys.accessToken, token);
          signInProvider && localStorage.setItem(LocalStorageKeys.signInProvider, signInProvider);
          return this.sendRequest(method, endpoint, data, otherConfig);
        }
        localStorage.removeItem(LocalStorageKeys.expirationTime);
        localStorage.removeItem(LocalStorageKeys.accessToken);
        localStorage.removeItem(LocalStorageKeys.signInProvider);
        window.location.reload();
      }
      throw e;
    }
  }

  async getAttachment(url: string) {
    const token = (localStorage.getItem(LocalStorageKeys.accessToken) as string) || {};

    const config: AxiosRequestConfig = {
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      responseType: 'blob',
    };

    const response = await axios(config);
    return response.data;
  }

  async sendFile(endpoint: string, file: any, fileId: string, options?: { setProgress?: any; setCancelToken?: any }) {
    const source = axios.CancelToken.source();
    if (options && options.setCancelToken) {
      options.setCancelToken(source);
    }
    const url = `${this.api}${endpoint}`;
    const token = (localStorage.getItem(LocalStorageKeys.accessToken) as string) || {};

    const config: AxiosRequestConfig = {
      method: 'POST',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data', // Important for sending files
      },
      responseType: 'blob',
      onUploadProgress: progressEvent => {
        if (options && options.setProgress && progressEvent.total) {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          options.setProgress(progress);
        }
      },
      cancelToken: source.token,
    };

    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileId', fileId);

      const response = await axios.post(url, formData, config);

      return response?.status;
    } catch (error) {
      console.error('Error sending file to API:', error);
      throw error;
    }
  }
}
