import { CreateWorkflowRunDto } from '../models/Workflows';
import { ApiConfig } from './ApiConfig';

export class WorkflowApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  async getAllWorkflowsByOrganizaton(organizationId: string) {
    return await this.apiConfig.sendRequest('GET', `/workflows/getAll/${organizationId}`);
  }

  //RUNS
  async createWorkflowRun(createWorkflowRunDto: CreateWorkflowRunDto) {
    return await this.apiConfig.sendRequest('POST', '/workflows/run', createWorkflowRunDto);
  }

  async findOneRun(runId: string) {
    return await this.apiConfig.sendRequest('GET', `/workflows/getOne/runs/${runId}`);
  }

  async findAllRunsByUserId(userId: string) {
    return await this.apiConfig.sendRequest('GET', `/workflows/getAllUser/runs/${userId}`);
  }

  async deleteRun(workflowRunId: string) {
    return await this.apiConfig.sendRequest('DELETE', `/workflows/runs/${workflowRunId}`);
  }
}
