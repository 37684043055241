import React, { useEffect, useState } from 'react';
import { Popover, List, ListItem } from '@mui/material';
import { observer } from 'mobx-react';

import { useStore } from '../../../hooks/useStore';
import { CustomSearchBar } from '../../../components/CustomSearchBar/CustomSearchBar';
import { AgentOption, Option } from './ModelTypeSelection';
import { OrganizationModel } from '../../../models/OrganizationModel';
import backArrowIcon from '../../../assets/icons/chat/back-arrow.svg';
import agent1Icon from '../../../assets/icons/chat/agent-1.svg';
import SelectFortressModal from '../../../components/modal/SelectFortressModal';
import SupportedAiProviderLogo from '../../../components/SupportedProviderLogo/SupportedAiProviderLogo';
import SelectADCBModal from '../../../components/modal/SelectADCBModal';
import SelectBrotherHoodModal from '../../../components/modal/SelectBrotherHoodModal';
import { Models } from '../../../constants/models';

interface Props {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  selectedOption: Option | null;
  setDropdownState: Function;
}

const ModelSelectionPopover: React.FC<Props> = ({ anchorEl, onClose, selectedOption, setDropdownState }) => {
  const [displayOptions, setDisplayOptions] = useState<OrganizationModel[] | AgentOption[]>();
  const [searchValue, setSearchValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showAdcbModal, setShowAdcbModal] = useState(false);
  const [showBrthrHoodModal, setShowBrthrHoodModal] = useState(false);

  const {
    modelStore: { organizationModels, setSelectedModel },
    conversationStore: {
      setPrimaryConversationModelSettings,
      setFortressSolutionId,
      isAgentModalOpen,
      setIsAgentModal,
      setIsBackBtnUsed,
    },
  } = useStore();

  const enabledOrganizationModels = organizationModels.filter((organizationModel: OrganizationModel) => {
    return organizationModel.isActive;
  });

  useEffect(() => {
    setSearchValue('');
  }, [anchorEl]);

  useEffect(() => {
    if (selectedOption?.id === 1) {
      const filteredOptions = enabledOrganizationModels?.filter(
        item => item.displayName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
      ) as OrganizationModel[];
      setDisplayOptions(filteredOptions);
    } else if (selectedOption?.id === 2) {
      const filtered = [
        { displayName: 'F1.0 RAG - Agent', type: 'Agent', providerName: 'Fortress' },
        { displayName: 'A1 RAG - Agent', type: 'Agent', providerName: 'ADCB' },
        { displayName: 'BH1 RAG - Agent', type: 'Agent', providerName: 'Brotherhood' },
      ].filter(item => item.displayName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
      setDisplayOptions(filtered);
    }
  }, [searchValue, selectedOption?.id, anchorEl]);

  const handleSelectOption = (model: OrganizationModel) => {
    // TODO: these kinds of check are not good at all
    // Create types for this
    setSelectedModel(model);
    if (model?.type !== 'Agent') {
      setPrimaryConversationModelSettings({
        accuracy: 0.8,
        length: 1024,
        model: model?.modelVersion as string,
        providerName: model?.providerName as string,
        modelDisplayName: model?.displayName as string,
      });
    } else {
      console.log('model ------------------->> ', model);
      if (model.type === 'Agent' && model.providerName === 'Fortress') {
        setPrimaryConversationModelSettings({
          accuracy: 0.8,
          length: 1024,
          model: 'F1.0 RAG',
          providerName: 'F1.0 RAG',
          modelDisplayName: 'F1.0 RAG',
        });

        const a = crypto.randomUUID();

        setFortressSolutionId(a);
        setShowModal(true);
      } else if (model.type === 'Agent' && model.providerName === 'Brotherhood') {
        console.log('brother hood called');
        setPrimaryConversationModelSettings({
          accuracy: 0.8,
          length: 1024,
          model: 'BH1 RAG',
          providerName: 'BH1 RAG',
          modelDisplayName: 'BH1 RAG',
        });

        const a = crypto.randomUUID();

        setFortressSolutionId(a);
        setShowBrthrHoodModal(true);
      } else {
        setPrimaryConversationModelSettings({
          accuracy: 0.8,
          length: 1024,
          model: 'A1 RAG',
          providerName: 'A1 RAG',
          modelDisplayName: 'A1 RAG',
        });

        const a = crypto.randomUUID();

        setFortressSolutionId(a);
        setShowAdcbModal(true);
      }
    }
    onClose();
  };

  return (
    <>
      <Popover
        open={!!anchorEl && !!selectedOption}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          <div style={{ display: 'flex' }}>
            <img
              src={backArrowIcon}
              alt="back"
              style={{ marginLeft: '12px' }}
              onClick={() => {
                setDropdownState(true);
                onClose();
              }}
            />
            <div style={{ fontWeight: 'bold', margin: '24px 4px', padding: '0px 8px' }}>
              {selectedOption?.id === 1 ? 'Models' : 'Agents'}
            </div>
          </div>
          <CustomSearchBar
            onChange={setSearchValue}
            onCancelSearch={() => setSearchValue('')}
            placeholder={'Search'}
            sx={{ marginBottom: '24px', padding: '0px 8px', flex: 3 }}
          />
          {displayOptions
            ?.filter(item => item.displayName !== Models.SQL_MODEL.displayName) // removing SQL model
            ?.map(model => (
              <ListItem
                onClick={() => {
                  handleSelectOption(model as OrganizationModel);
                }}
              >
                {model.displayName.includes('Agent') ? (
                  <img src={agent1Icon} alt="back" style={{ marginRight: '8px' }} />
                ) : (
                  <SupportedAiProviderLogo
                    provider={(model as OrganizationModel).providerName}
                    size={24}
                    style={{ marginRight: '8px' }}
                  />
                )}
                {model.displayName}
              </ListItem>
            ))}
        </List>
      </Popover>
      {(showModal || isAgentModalOpen) && (
        <SelectFortressModal
          isOpen={showModal || isAgentModalOpen}
          onClose={() => {
            setShowModal(false);
            setIsAgentModal(false);
            setIsBackBtnUsed(false);
          }}
        />
      )}
      {(showAdcbModal || isAgentModalOpen) && (
        <SelectADCBModal
          isOpen={showAdcbModal || isAgentModalOpen}
          onClose={() => {
            setShowAdcbModal(false);
            setIsAgentModal(false);
            setIsBackBtnUsed(false);
          }}
        />
      )}
      {(showBrthrHoodModal || isAgentModalOpen) && (
        <SelectBrotherHoodModal
          isOpen={showBrthrHoodModal || isAgentModalOpen}
          onClose={() => {
            setShowBrthrHoodModal(false);
            setIsAgentModal(false);
            setIsBackBtnUsed(false);
          }}
        />
      )}
    </>
  );
};

export default observer(ModelSelectionPopover);
