import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { COLOR_WHITE } from '../../../constants/colors';
import { SIZES_SMALL } from '../../../constants/sizes';

type HtmlStringResultInterface = string;

const HtmlStringResultsPage = () => {
  const [workflowResult, setWorkflowResult] = useState<HtmlStringResultInterface>();
  const {
    workFlowStore: { selectedWorkflowRun, setSelectedWorkflowRun },
  } = useStore();

  useEffect(() => {
    setWorkflowResult(selectedWorkflowRun.result);
  }, [selectedWorkflowRun.id]);

  return (
    <PageContainer id="resultElement">
      <div
        dangerouslySetInnerHTML={{
          __html: workflowResult ? workflowResult : '',
        }}
      />
    </PageContainer>
  );
};

const PageContainer = styled(Box)`
  align-items: center;
  height: 100%;
  margin: 0;
  gap: 16px;
  background: ${COLOR_WHITE};
  border-radius: 8px;
  overflow: auto;
  padding: 24px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: auto;
  }
`;

export default observer(HtmlStringResultsPage);
