import { OrganizationModel } from '../../models/OrganizationModel';
import React, { useEffect } from 'react';
import { useStore } from '../../hooks/useStore';
import { Avatar } from '@mui/material';
import SupportedAiProviderLogo from '../SupportedProviderLogo/SupportedAiProviderLogo';

interface ModelLogoPictureProps {
  model: OrganizationModel;
  size?: number;
}

const ModelLogoPicture = ({ model, size }: ModelLogoPictureProps) => {
  const sizeStyles = { width: `${size || 32}px`, height: `${size || 32}px` };

  const {
    modelStore: { updateOrganizationModel },
    appState: { s3DocumentsApi },
  } = useStore();

  useEffect(() => {
    // (async () => {
    //   if (
    //     model?.id &&
    //     model?.logoPictureFileKey &&
    //     model?.logoPictureUrlExpiration &&
    //     new Date(model?.logoPictureUrlExpiration).getTime() < new Date().getTime()
    //   ) {
    //     const readUrl = await s3DocumentsApi.generateAssetsReadUrl(model?.logoPictureFileKey);

    //     await updateOrganizationModel({
    //       ...model,
    //       logoPicture: readUrl.signedUrl,
    //       logoPictureUrlExpiration: readUrl.expirationDate,
    //     });
    //   }
    // })();
  }, [model]);

  return model.logoPicture ? (
    <Avatar src={model.logoPicture} sx={sizeStyles} />
  ) : (
    <SupportedAiProviderLogo provider={model.providerName} size={30} />
  );
};

export default ModelLogoPicture;
